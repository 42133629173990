define("@streethub/mirage-api/resources/boutique", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    context.optionalData = null;

    function findResources(schema, query) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, query);
      return schema.boutiques.where(queryParams);
    }

    server.get('boutiques', function (schema, request) {
      return findResources(schema, request.queryParams);
    });
    server.get('boutiques/:id', function (schema, request) {
      const id = request.params.id;
      const boutique = schema.boutiques.find(id);

      if (!boutique) {
        return schema.boutiques.none();
      }

      return boutique;
    });
    server.post('boutiques', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);

      if ((0, _request.hasGetMethodOverrideHeader)(request)) {
        return findResources(schema, requestBody);
      }

      return schema.boutiques.create(requestBody);
    });
    server.put('boutiques/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.boutiques.update(id, body);
    });
    server.del('boutiques/:id', function (schema, request) {
      const id = request.params.id;
      const boutique = schema.boutiques.find(id);
      return boutique.destroy();
    });
    server.post('boutiques/:id/accept-curate-terms', (schema, request) => {
      const id = request.params.id;
      const boutique = schema.boutiques.find(id);

      if (!boutique) {
        return schema.boutiques.none();
      }

      const now = new Date();
      return boutique.update({
        curate_terms_and_conditions: { ...boutique.curate_terms_and_conditions,
          accepted_at: now.toISOString()
        }
      });
    });
  }
});