define("@streethub/mirage-api/resources/curate-brand-tag", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null; // Wrap find logic here because we can also find resources
    // using the X-HTTP-Method-Override=GET header

    function findResources(schema, query) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, query);
      return schema.curateBrandTags.where(queryParams);
    }

    server.get('brand_tags', function (schema, request) {
      return findResources(schema, request.queryParams);
    });
    server.post('brand_tags', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);

      if ((0, _request.hasGetMethodOverrideHeader)(request)) {
        return findResources(schema, requestBody);
      }

      return schema.curateBrandTags.create(requestBody);
    });
  }
});