define("ember-toggle/components/x-toggle-switch", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span
    class="x-toggle-container
      {{@size}}
      {{if @disabled 'x-toggle-container-disabled'}}
      {{if @value 'x-toggle-container-checked'}}
    "
    ...attributes
    {{did-pan
      onPanStart=this.handlePan
      onPan=this.handlePan
      pointerTypes=(array 'touch' 'mouse')
    }}
    {{will-destroy this.removeListener}}
  >
    <input
      class='x-toggle'
      type='checkbox'
      checked={{@toggled}}
      disabled={{@disabled}}
      id={{@forId}}
      name={{@name}}
      {{on 'change' this.onChange}}
    />
  
    <label for={{this.effectiveForId}}>
      <div
        id="x-toggle-visual-{{@forId}}"
        role='checkbox'
        class="x-toggle-btn
          {{this.themeClass}}
          {{@variant}}
          {{@size}}
          {{if @disabled ' x-toggle-disabled'}}
        "
        aria-owns={{@forId}}
        aria-checked={{@toggled}}
        data-tg-on={{@onLabel}}
        data-tg-off={{@offLabel}}
      >
      </div>
    </label>
  </span>
  */
  {
    "id": "uYYVKVB/",
    "block": "{\"symbols\":[\"@value\",\"@disabled\",\"@size\",\"&attrs\",\"@toggled\",\"@forId\",\"@name\",\"@variant\",\"@onLabel\",\"@offLabel\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"x-toggle-container\\n    \",[32,3],\"\\n    \",[30,[36,0],[[32,2],\"x-toggle-container-disabled\"],null],\"\\n    \",[30,[36,0],[[32,1],\"x-toggle-container-checked\"],null],\"\\n  \"]]],[17,4],[4,[38,2],null,[[\"onPanStart\",\"onPan\",\"pointerTypes\"],[[32,0,[\"handlePan\"]],[32,0,[\"handlePan\"]],[30,[36,1],[\"touch\",\"mouse\"],null]]]],[4,[38,3],[[32,0,[\"removeListener\"]]],null],[12],[2,\"\\n  \"],[11,\"input\"],[24,0,\"x-toggle\"],[16,\"checked\",[32,5]],[16,\"disabled\",[32,2]],[16,1,[32,6]],[16,3,[32,7]],[24,4,\"checkbox\"],[4,[38,4],[\"change\",[32,0,[\"onChange\"]]],null],[12],[13],[2,\"\\n\\n  \"],[10,\"label\"],[15,\"for\",[32,0,[\"effectiveForId\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,1,[31,[\"x-toggle-visual-\",[32,6]]]],[14,\"role\",\"checkbox\"],[15,0,[31,[\"x-toggle-btn\\n        \",[32,0,[\"themeClass\"]],\"\\n        \",[32,8],\"\\n        \",[32,3],\"\\n        \",[30,[36,0],[[32,2],\" x-toggle-disabled\"],null],\"\\n      \"]]],[15,\"aria-owns\",[32,6]],[15,\"aria-checked\",[32,5]],[15,\"data-tg-on\",[32,9]],[15,\"data-tg-off\",[32,10]],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"array\",\"did-pan\",\"will-destroy\",\"on\"]}",
    "moduleName": "ember-toggle/components/x-toggle-switch.hbs"
  });

  let XToggleSwitch = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class XToggleSwitch extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "labelDisabled", _descriptor, this);
    }

    get effectiveForId() {
      return this.labelDisabled ? null : this.args.forId;
    }

    get themeClass() {
      let theme = this.args.theme || 'default';
      return `x-toggle-${theme}`;
    }

    handlePan(touchData) {
      if (this.args.disabled) {
        return;
      }

      const isToggled = touchData.current.distanceX > 0;
      this.args.sendToggle(isToggled);

      this._disableLabelUntilMouseUp();
    }

    onChange(e) {
      if (this.args.disabled) {
        return;
      }

      this.args.sendToggle(e.target.checked);
    }

    removeListener() {
      this._removeListener();
    }
    /*
      When you pan with a mouse and release the mouse button over the <label>
      element, a click event happens and returns the toggle to its initial
      state. :(
       To prevent this, we need to make the label non-functional until after the
      mouse button is released.
     */


    _disableLabelUntilMouseUp() {
      if (this.labelDisabled) {
        return;
      }

      const _listener = () => {
        Ember.run.next(() => {
          if (this.isDestroying || this.isDestroyed) {
            return;
          }

          this._removeListener();

          this.labelDisabled = false;
        });
      };

      this.labelDisabled = true;
      this._listener = _listener;
      document.addEventListener('mouseup', _listener);
    }

    _removeListener() {
      const _listener = this._listener;

      if (_listener) {
        document.removeEventListener('mouseup', _listener);
        this._listener = null;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "labelDisabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handlePan", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handlePan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeListener", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeListener"), _class.prototype)), _class));
  _exports.default = XToggleSwitch;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XToggleSwitch);
});