define("@streethub/mirage-api/factories/stock-line", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    archived: false,
    sales: false,

    _id(i) {
      return i;
    },

    quantity() {
      return _faker.default.datatype.number({
        min: 1,
        max: 50
      });
    },

    stock() {
      return this.quantity > 0 || this.quantity === -1;
    },

    sku() {
      return _faker.default.datatype.uuid();
    },

    variants() {
      return [];
    },

    dimensions() {
      return {
        height: null,
        width: null,
        length: null
      };
    },

    local_price() {
      return {
        amount: _faker.default.datatype.number({
          min: 99,
          max: 99999
        }),
        currency: 'GBP'
      };
    },

    local_sales_price() {
      return {};
    },

    channels() {
      return {
        trouva: {
          enabled: true
        },
        madecom: {
          enabled: false
        }
      };
    },

    size() {
      return {
        label: _faker.default.random.word()
      };
    },

    inSale: (0, _emberCliMirage.trait)({
      sales: true,

      local_sales_price() {
        return {
          amount: _faker.default.datatype.number({
            min: 99,
            max: 99999
          }),
          currency: 'GBP'
        };
      }

    }),
    outOfStock: (0, _emberCliMirage.trait)({
      quantity: 0,

      marked_as_oos_at() {
        return _faker.default.date.recent();
      }

    }),
    unlimited: (0, _emberCliMirage.trait)({
      quantity: -1
    }),
    madecomEnabled: (0, _emberCliMirage.trait)({
      channels() {
        return {
          trouva: {
            enabled: true
          },
          madecom: {
            enabled: true
          }
        };
      }

    })
  });

  _exports.default = _default;
});