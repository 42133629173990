define("ember-svg-jar/inlined/made-monogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 11.323V.677h2.038L6.57 8.19H5.49L9.947.677h2.023L12 11.323H9.688L9.673 4.22h.426l-3.56 5.978H5.43L1.796 4.22h.517v7.103H0z\" fill=\"#CD7F32\"/>",
    "attrs": {
      "viewBox": "0 0 12 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});