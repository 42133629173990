define("ember-svg-jar/inlined/handsfree-bulk-upload-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"202.48\" height=\"141.492\" fill=\"#BBA9CD\" opacity=\".2\" rx=\"70.746\"/><path fill=\"#242038\" d=\"M188.465 71.402c0-.304-.305-.609-.61-.609h-4.468v-4.469c0-.305-.305-.61-.61-.61h-1.625a.617.617 0 00-.609.61v4.469h-4.469a.617.617 0 00-.609.61v1.624c0 .356.254.61.609.61h4.469v4.468c0 .356.254.61.609.61h1.625c.305 0 .61-.254.61-.61v-4.468h4.468c.305 0 .61-.254.61-.61v-1.625zm6.094.813c0-6.957-5.637-12.594-12.594-12.594s-12.594 5.637-12.594 12.594 5.637 12.594 12.594 12.594 12.594-5.637 12.594-12.594zm-2.438 0c0 5.637-4.57 10.156-10.156 10.156a10.12 10.12 0 01-10.156-10.156c0-5.586 4.519-10.156 10.156-10.156 5.586 0 10.156 4.57 10.156 10.156z\"/><path fill=\"#F4F4F5\" stroke=\"#242038\" stroke-width=\"4\" d=\"M122.193 13.838a4 4 0 014-4h27.71v124.899h-27.71a4 4 0 01-4-4v-116.9z\"/><rect width=\"100.513\" height=\"124.899\" x=\"2.871\" y=\"9.838\" fill=\"#fff\" stroke=\"#242038\" stroke-width=\"4\" rx=\"4\"/><path fill=\"#9B99A4\" d=\"M81.96 36.272c.691.395 1.186.987 1.482 1.776s.198 1.579-.198 2.27l-5.04 10.359c-.395.69-.988 1.184-1.779 1.48-.692.296-1.581.197-2.273-.198l-4.744-2.368v23.482a4.425 4.425 0 01-4.447 4.44H42.13c-2.372 0-4.349-1.974-4.349-4.44V49.592l-4.843 2.367c-.691.395-1.482.494-2.273.198-.79-.296-1.383-.79-1.779-1.579l-5.04-10.26c-.395-.691-.395-1.48-.198-2.27.297-.79.791-1.381 1.483-1.776l18.58-9.274.79 1.184c1.483 2.17 5.041 3.552 9.093 3.552 3.953 0 7.511-1.381 8.994-3.552l.79-1.184 18.58 9.274z\"/><rect width=\"82.739\" height=\"5.226\" x=\"11.324\" y=\"94.061\" fill=\"#C8C7CD\" rx=\"2.613\"/><rect width=\"82.739\" height=\"5.226\" x=\"11.324\" y=\"107.125\" fill=\"#C8C7CD\" rx=\"2.613\"/><rect width=\"39.192\" height=\"5.226\" x=\"11.324\" y=\"120.189\" fill=\"#C8C7CD\" rx=\"2.613\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 203 142"
    }
  };
  _exports.default = _default;
});