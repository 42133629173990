define("ember-google-maps/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id={{@id}} class="ember-google-map" ...attributes {{g-map/did-insert @onCanvasReady}}>{{yield}}</div>
  */
  {
    "id": "Lnsy0WnM",
    "block": "{\"symbols\":[\"@id\",\"&attrs\",\"@onCanvasReady\",\"&default\"],\"statements\":[[11,\"div\"],[16,1,[32,1]],[24,0,\"ember-google-map\"],[17,2],[4,[38,0],[[32,3]],null],[12],[18,4,null],[13]],\"hasEval\":false,\"upvars\":[\"g-map/did-insert\"]}",
    "moduleName": "ember-google-maps/components/g-map/canvas.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});