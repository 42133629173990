define("ember-svg-jar/inlined/curate-brand-referral-modal-shape-04", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".5\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.476 2.099C8.523.856 11.07.019 13.371.52c2.265.493 3.596 2.445 4.925 4.148 1.192 1.527 2.04 3.21 2.296 5.104.265 1.965.063 3.957-.822 5.828-.992 2.097-2.062 4.763-4.471 5.363-2.452.611-4.15-2.194-6.59-2.605-2.43-.41-5.466 1.69-7.318.26-1.808-1.395-.91-4.202-.658-6.416.21-1.851 1.02-3.527 1.977-5.21 1.027-1.806 1.9-3.76 3.766-4.893z\" fill=\"#F0E2DF\"/>",
    "attrs": {
      "width": "21",
      "height": "22",
      "viewBox": "0 0 21 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});