define("@streethub/mirage-api/factories/user", ["exports", "ember-cli-mirage", "faker", "@streethub/mirage-api/utils/object-id"], function (_exports, _emberCliMirage, _faker, _objectId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    upworker: false,
    role: 'hub',

    first_name() {
      return _faker.default.name.firstName();
    },

    last_name() {
      return _faker.default.name.lastName();
    },

    full_name() {
      return `${this.first_name} ${this.last_name}`;
    },

    mobile() {
      return _faker.default.phone.phoneNumber();
    },

    phone() {
      return _faker.default.phone.phoneNumber();
    },

    postcode() {
      return _faker.default.address.zipCode();
    },

    email() {
      return _faker.default.internet.email();
    },

    password() {
      return _faker.default.internet.password();
    },

    created_at() {
      return _faker.default.date.past();
    },

    avatar() {
      return _faker.default.image.imageUrl();
    },

    afterCreate(item, server) {
      const propertiesToUpdate = {
        _id: item.id
      };

      if (!item.brands) {
        let brand = null;

        if (server.db.brands && server.db.brands.length) {
          brand = server.db.brands[0];
        } else {
          brand = server.create('brand');
        }

        propertiesToUpdate['brands'] = [{
          _id: (0, _objectId.default)(),
          brand: brand._id
        }];
      } // Set "_id" to be the same as mirage's "id"


      item.update(propertiesToUpdate);
    }

  });

  _exports.default = _default;
});