define("@streethub/mirage-api/resources/curate-product", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('products', function (schema, request) {
      const {
        queryParams: {
          sku: querySku,
          ...restQueryParams
        }
      } = request;
      const queryParams = (0, _request.normalizeQueryParams)(schema, restQueryParams);
      let products = schema.curateProducts.where(queryParams);

      if (querySku) {
        products = products.filter(_ref => {
          let {
            stock_lines
          } = _ref;
          return stock_lines.find(_ref2 => {
            let {
              sku
            } = _ref2;
            return sku === querySku;
          });
        });
      }

      return products;
    });
    server.get('products/:id', function (schema, request) {
      const id = request.params.id;
      const curateProduct = schema.curateProducts.find(id);

      if (!curateProduct) {
        return schema.curateProducts.none();
      }

      return curateProduct;
    });
    server.post('products', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);

      if ((0, _request.hasGetMethodOverrideHeader)(request)) {
        return findResources(schema, requestBody);
      }

      return schema.curateProducts.create(requestBody);
    });
    server.put('products/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.curateProducts.update(id, body);
    });
  }
});