define("ember-google-maps/components/g-map/autocomplete", ["exports", "ember-google-maps/components/g-map/map-component"], function (_exports, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (has-block)}}
    {{yield (hash setup=this.getInput)}}
  {{else}}
    <input id={{this.id}} ...attributes {{g-map/did-insert this.getInput}} />
  {{/if}}
  */
  {
    "id": "ui8tt1oN",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,1],null,[[\"setup\"],[[32,0,[\"getInput\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"input\"],[16,1,[32,0,[\"id\"]]],[17,1],[4,[38,0],[[32,0,[\"getInput\"]]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"g-map/did-insert\",\"hash\",\"if\"]}",
    "moduleName": "ember-google-maps/components/g-map/autocomplete.hbs"
  });

  let Autocomplete = (_dec = Ember._action, (_class = class Autocomplete extends _mapComponent.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "id", `ember-google-maps-autocomplete-${Ember.guidFor(this)}`);
    }

    get name() {
      return 'autocompletes';
    }

    setup(options, events) {
      (false && !(this.inputElement) && Ember.assert(`
ember-google-maps: No input found for autocomplete.

When using the block form of the autocomplete component, make sure to call the “setup” method on your input to let autocomplete know about it:

<map.autocomplete as |autocomplete|>
  <input {{did-insert autocomplete.setup}} />
</map.autocomplete>

Did you mean to use the block form? You can also do the following:

<map.autocomplete id="my-custom-id" class="my-custom-class" />
      `, this.inputElement));
      let autocomplete = new google.maps.places.Autocomplete(this.inputElement, options);
      this.addEventsToMapComponent(autocomplete, events, this.publicAPI); // Compatibility: Register the custom `onSearch` event.

      this.addEventsToMapComponent(autocomplete, {
        onPlaceChanged: this.args.onSearch
      }, this.publicAPI);
      return autocomplete;
    }

    update(mapComponent) {
      var _mapComponent$setOpti;

      mapComponent === null || mapComponent === void 0 ? void 0 : (_mapComponent$setOpti = mapComponent.setOptions) === null || _mapComponent$setOpti === void 0 ? void 0 : _mapComponent$setOpti.call(mapComponent, this.newOptions);
      return mapComponent;
    }

    getInput(input) {
      this.inputElement = input;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "getInput", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "getInput"), _class.prototype)), _class));
  _exports.default = Autocomplete;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Autocomplete);
});