define("@streethub/mirage-api/factories/boutique", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getBulkUploadHistoryEntry = function () {
    let extra = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      bulk_operation_id: _faker.default.datatype.uuid(),
      filename: _faker.default.random.words(),
      products: [],
      status: 'created',
      user_role: 'retail',
      created_at: _faker.default.date.past(),
      created_by: _faker.default.datatype.uuid(),
      ...extra
    };
  };

  var _default = _emberCliMirage.Factory.extend({
    archived: false,

    name() {
      return _faker.default.company.companyName();
    },

    slug() {
      return this.name.replace(' ', '-');
    },

    description() {
      return _faker.default.lorem.sentences(2);
    },

    mini_description() {
      return this.description.split('.')[0];
    },

    story_title() {
      return _faker.default.lorem.sentences(2);
    },

    story_image() {
      return _faker.default.image.imageUrl();
    },

    address_line_1() {
      return _faker.default.address.streetAddress();
    },

    address_line_2() {
      return _faker.default.address.secondaryAddress();
    },

    address_postcode() {
      return _faker.default.address.zipCode();
    },

    address_city() {
      return _faker.default.address.city();
    },

    address_county() {
      return _faker.default.address.county();
    },

    postcode() {
      return this.address_postcode;
    },

    support_email() {
      return _faker.default.internet.email();
    },

    support_phone() {
      return _faker.default.phone.phoneNumber();
    },

    currency() {
      return _faker.default.random.arrayElement(['EUR', 'GBP', 'DKK', 'SEK']);
    },

    oh() {
      const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return daysOfTheWeek.map((day, index) => ({
        appointment: false,
        active: true,
        time: '10:00-18:30',
        label: day,
        day: index,
        selectedStart: '10:00',
        selectedEnd: '18:30'
      }));
    },

    features() {
      return [];
    },

    isArchived: (0, _emberCliMirage.trait)({
      archived: true
    }),
    isOssRegistered: (0, _emberCliMirage.trait)({
      eu_vat() {
        return {
          status: 'oss',
          registration_number: 'EU1234567890'
        };
      }

    }),
    withBulkUploadHistory: (0, _emberCliMirage.trait)({
      bulk_upload_history() {
        return [getBulkUploadHistoryEntry(), getBulkUploadHistoryEntry()];
      }

    }),

    afterCreate(boutique, server) {
      const propertiesToUpdate = {
        _id: boutique.id
      };

      if (!boutique.address_country) {
        const country = server.create('country');
        Object.assign(propertiesToUpdate, {
          address_country: country
        });
      }

      boutique.update(propertiesToUpdate);
    }

  });

  _exports.default = _default;
});