define("@streethub/mirage-api/factories/packaging-promotion-code", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const rateGbpToEur = 1.33;

  var _default = _emberCliMirage.Factory.extend({
    id() {
      return `promo_${_faker.default.datatype.uuid()}`;
    },

    _id() {
      return this.id;
    },

    code() {
      return `${_faker.default.random.word().toUpperCase()}${_faker.default.datatype.number({
        min: 10,
        max: 50
      })}`;
    },

    amount: (0, _emberCliMirage.trait)({
      type: 'amount',

      value() {
        return _faker.default.datatype.number({
          min: 500,
          max: 10000
        });
      },

      value_GBP() {
        return {
          amount: this.value,
          currency: 'GBP'
        };
      },

      value_EUR() {
        return {
          amount: Math.round(this.value * rateGbpToEur),
          currency: 'EUR'
        };
      }

    }),
    percentage: (0, _emberCliMirage.trait)({
      type: 'percentage',

      value() {
        return _faker.default.datatype.number({
          min: 5,
          max: 70
        });
      }

    }),
    freeShipping: (0, _emberCliMirage.trait)({
      type: 'free_shipping'
    }),
    minimumValue: (0, _emberCliMirage.trait)({
      minimum_value() {
        return _faker.default.datatype.number({
          min: 1000,
          max: 500000
        });
      },

      minimum_value_GBP() {
        return {
          amount: this.minimum_value,
          currency: 'GBP'
        };
      },

      minimum_value_EUR() {
        return {
          amount: Math.round(this.minimum_value * rateGbpToEur),
          currency: 'EUR'
        };
      }

    }),
    expired: (0, _emberCliMirage.trait)({
      expired_at() {
        return _faker.default.date.past();
      }

    }),

    afterCreate(promotionCode, server) {
      if (promotionCode.currency) {
        return;
      }

      const {
        attrs: currency
      } = server.create('currency');
      promotionCode.update({
        currency
      });
    }

  });

  _exports.default = _default;
});