define("ember-svg-jar/inlined/curate-brand-referral-modal-shape-03", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".5\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.21 17.762C2.296 16.327.57 14.276.175 11.953c-.388-2.285.928-4.247 2.012-6.115.972-1.676 2.217-3.092 3.878-4.035C7.79.823 9.714.269 11.778.392c2.316.138 5.189.137 6.645 2.148 1.482 2.047-.488 4.67.04 7.086.527 2.408 3.607 4.442 2.971 6.693-.62 2.197-3.559 2.41-5.707 3.003-1.796.496-3.653.37-5.572.11-2.058-.28-4.197-.36-5.944-1.67z\" fill=\"#F0E2DF\"/>",
    "attrs": {
      "width": "22",
      "height": "20",
      "viewBox": "0 0 22 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});