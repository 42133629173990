define("ember-svg-jar/inlined/packaging-blob-bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M49.314 124.912c-14.41-1.046-29.855-5.813-39.422-16.263C.48 98.366.771 84.199.194 71.259c-.517-11.606 1.195-22.785 6.564-32.926 5.573-10.525 13.54-19.556 24.433-25.618C43.405 5.916 57.994-3.387 71.938 2.055c14.195 5.54 12.73 25.155 23.284 35.648 10.514 10.454 32.783 10.75 36.886 24.173 4.006 13.104-10.231 23.694-19.214 33.64-7.508 8.314-17.355 13.686-27.947 18.583-11.365 5.255-22.492 11.768-35.633 10.813z\"/>",
    "attrs": {
      "viewBox": "0 0 133 126",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});