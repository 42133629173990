define("ember-power-select-typeahead/templates/components/power-select-typeahead/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uql3ac8G",
    "block": "{\"symbols\":[\"@select\",\"@placeholder\",\"@onInput\",\"@onFocus\",\"@onBlur\"],\"statements\":[[10,\"input\"],[15,2,[32,0,[\"text\"]]],[14,\"role\",\"combobox\"],[15,1,[31,[\"ember-power-select-typeahead-input-\",[32,1,[\"uniqueId\"]]]]],[14,0,\"ember-power-select-typeahead-input ember-power-select-search-input\"],[14,\"autocomplete\",\"off\"],[14,\"autocorrect\",\"off\"],[14,\"autocapitalize\",\"off\"],[14,\"spellcheck\",\"false\"],[15,\"placeholder\",[32,2]],[15,\"oninput\",[32,3]],[15,\"onfocus\",[32,4]],[15,\"onblur\",[32,5]],[15,\"disabled\",[32,1,[\"disabled\"]]],[15,\"onkeydown\",[30,[36,0],[[32,0],\"handleKeydown\"],null]],[15,\"onmousedown\",[30,[36,0],[[32,0],\"stopPropagation\"],null]],[14,4,\"search\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,1,[\"loading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"ember-power-select-typeahead-loading-indicator\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "ember-power-select-typeahead/templates/components/power-select-typeahead/trigger.hbs"
  });

  _exports.default = _default;
});