define("ember-google-maps/components/g-map/marker", ["exports", "ember-google-maps/components/g-map/typical-map-component", "ember-google-maps/utils/helpers"], function (_exports, _typicalMapComponent, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
    infoWindow=(component "g-map/info-window" getContext=@getContext target=this.mapComponent))}}
  */
  {
    "id": "Qi+sG74T",
    "block": "{\"symbols\":[\"@getContext\",\"&default\"],\"statements\":[[18,2,[[30,[36,1],null,[[\"infoWindow\"],[[30,[36,0],[\"g-map/info-window\"],[[\"getContext\",\"target\"],[[32,1],[32,0,[\"mapComponent\"]]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-google-maps/components/g-map/marker.hbs"
  });

  class Marker extends _typicalMapComponent.default {
    get name() {
      return 'markers';
    }

    get newOptions() {
      if (!this.args.position) {
        this.options.position = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }

      return this.options;
    }

    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.Marker(options);
    }

  }

  _exports.default = Marker;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Marker);
});