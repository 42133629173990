define("ember-toggle/components/x-toggle-label", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @show}}
    <label
      for={{@switchId}}
      class="toggle-text toggle-prefix {{this.type}}-label"
      ...attributes
      {{on 'click' this.clickLabel}}
    >
      {{#if (has-block)}}
        {{yield @label}}
      {{else}}
        {{@label}}
      {{/if}}
    </label>
  {{/if}}
  */
  {
    "id": "jol9jy+6",
    "block": "{\"symbols\":[\"@label\",\"&default\",\"@switchId\",\"&attrs\",\"@show\"],\"statements\":[[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"label\"],[16,\"for\",[32,3]],[16,0,[31,[\"toggle-text toggle-prefix \",[32,0,[\"type\"]],\"-label\"]]],[17,4],[4,[38,0],[\"click\",[32,0,[\"clickLabel\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "moduleName": "ember-toggle/components/x-toggle-label.hbs"
  });

  let XToggleLabel = (_dec = Ember._action, (_class = class XToggleLabel extends _component.default {
    get type() {
      return this.args.value ? 'on' : 'off';
    }

    clickLabel(e) {
      e.stopPropagation();
      e.preventDefault();
      this.args.sendToggle(this.args.value);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clickLabel", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clickLabel"), _class.prototype)), _class));
  _exports.default = XToggleLabel;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, XToggleLabel);
});