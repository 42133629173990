define("ember-google-maps/modifiers/g-map/did-insert", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)(function gMapDidInsert(element, _ref, named) {
    let [callback, ...positional] = _ref;
    (false && !(typeof callback === 'function') && Ember.assert('`g-map/did-insert` expects a function as its first positional argument.', typeof callback === 'function'));
    callback(element, positional, named);
  });

  _exports.default = _default;
});