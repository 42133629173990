define("@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-expiry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nhrKUwPG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,\"role\",\"mount-point\"],[12],[13],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[32,0,[\"stripeElement\"]],[32,0,[\"stripeError\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-expiry.hbs"
  });

  _exports.default = _default;
});