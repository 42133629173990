define("@streethub/mirage-api/utils/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasGetMethodOverrideHeader = hasGetMethodOverrideHeader;
  _exports.normalizeQueryParams = normalizeQueryParams;
  _exports.paginationParams = paginationParams;
  _exports.queryWithoutReservedKeywords = queryWithoutReservedKeywords;

  /**
   * Promenade has some reserved keywords for query params for paginating,
   * populating records, etc
   *
   * @param queryParams
   * @returns {object}
   */
  function queryWithoutReservedKeywords() {
    let queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      _,
      populate,
      plain,
      fields,
      limit,
      page,
      sort,
      skip_links,
      attach_related,
      metadata,
      ...rest
    } = queryParams;
    return rest;
  }
  /**
   *
   * @param schema
   * @param queryParams
   * @return {any}
   */


  function normalizeQueryParams(schema) {
    let queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const queryParamsWithoutReservedKeywords = queryWithoutReservedKeywords(queryParams);
    return Object.entries(queryParamsWithoutReservedKeywords).map(_ref => {
      let [key, value] = _ref;

      if (schema._registry[key]) {
        return [`${key}Id`, value];
      }

      return [key, value];
    }).reduce((prev, _ref2) => {
      let [key, value] = _ref2;
      return { ...prev,
        [key]: value
      };
    }, {});
  }
  /**
   * Extracts pagination params by deserializing the query params and falls back to default values for paginating
   *
   * @param queryParams
   * @return {{limit, page}}
   */


  function paginationParams() {
    let queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      limit: queryParams.limit ? Number(queryParams.limit) : 100,
      page: queryParams.page ? Number(queryParams.page) : 1
    };
  }
  /**
   * Checks if the "x-http-method-override" header exists and is equal to "get".
   * The implementation finds it in a case insensitive way, because clients can send whatever casing they want
   *
   * @param headers       - incoming request object
   * @return {boolean}    - true if header exists and has value 'get', false otherwise
   */


  function hasGetMethodOverrideHeader(_ref3) {
    let {
      requestHeaders
    } = _ref3;
    const getMethodOverrideIndex = Object.entries(requestHeaders).findIndex(_ref4 => {
      let [headerName, headerValue] = _ref4;
      return headerName.toLowerCase() === 'x-http-method-override' && headerValue.toLowerCase() === 'get';
    });
    return getMethodOverrideIndex > -1;
  }
});