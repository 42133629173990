define("@streethub/mirage-api/identity-managers/application", ["exports", "@streethub/mirage-api/utils/object-id"], function (_exports, _objectId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default {
    constructor() {
      this.ids = new Set();
    } // Returns a new unused unique identifier.


    fetch() {
      let id = (0, _objectId.default)();

      while (this.ids.has(id)) {
        id = (0, _objectId.default)();
      }

      this.ids.add(id);
      return id;
    } // Registers an identifier as used. Must throw if identifier is already used.


    set(id) {
      if (this.ids.has(id)) {
        throw new Error(`ID ${id} has already been used.`);
      }

      this.ids.add(id);
    } // Resets all used identifiers to unused.


    reset() {
      this.ids.clear();
    }

  }

  _exports.default = _default;
});