define("ember-intl/services/intl", ["exports", "ember-intl/-private/formatters", "ember-intl/-private/utils/is-array-equal", "ember-intl/-private/utils/normalize-locale", "ember-intl/-private/utils/get-dom", "ember-intl/-private/utils/hydrate", "ember-intl/-private/store/container"], function (_exports, _formatters, _isArrayEqual, _normalizeLocale, _getDom, _hydrate, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    /** @public **/
    formats: null,

    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: Ember.computed.readOnly('_translationContainer.locales'),

    /** @public **/
    locale: Ember.computed('_locale', {
      set(_, localeName) {
        const proposed = Ember.makeArray(localeName).map(_normalizeLocale.default);

        if (!(0, _isArrayEqual.default)(proposed, this._locale)) {
          Ember.set(this, '_locale', proposed);
          Ember.run.cancel(this._timer);
          this._timer = Ember.run.next(() => {
            this.trigger('localeChanged');

            this._updateDocumentLanguage(this._locale);
          });
        }

        return this._locale;
      },

      get() {
        return Ember.get(this, '_locale');
      }

    }),

    /**
     * Returns the first locale of the currently active locales
     *
     * @property primaryLocale
     * @public
     */
    primaryLocale: Ember.computed.readOnly('locale.0'),

    /** @public **/
    formatRelative: createFormatterProxy('relative'),

    /** @public **/
    formatMessage: createFormatterProxy('message'),

    /** @public **/
    formatNumber: createFormatterProxy('number'),

    /** @public **/
    formatTime: createFormatterProxy('time'),

    /** @public **/
    formatDate: createFormatterProxy('date'),

    /** @private **/
    _translationContainer: null,

    /** @private **/
    _locale: null,

    /** @private **/
    _timer: null,

    /** @private **/
    _formatters: null,

    /** @public **/
    init() {
      this._super(...arguments);

      const initialLocale = Ember.get(this, 'locale') || ['en-us'];
      this.setLocale(initialLocale);
      this._owner = Ember.getOwner(this); // Below issue can be ignored, as this is during the `init()` constructor.
      // eslint-disable-next-line ember/no-assignment-of-untracked-properties-used-in-tracking-contexts

      this._translationContainer = _container.default.create();
      this._formatters = this._createFormatters();

      if (!this.formats) {
        this.formats = this._owner.resolveRegistration('formats:main') || {};
      }

      (0, _hydrate.default)(this);
    },

    willDestroy() {
      this._super(...arguments);

      Ember.run.cancel(this._timer);
    },

    /** @private **/
    onError({
      /* kind, */
      error
    }) {
      throw error;
    },

    /** @public **/
    lookup(key, localeName) {
      const localeNames = this._localeWithDefault(localeName);

      let translation;

      for (let i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookup(localeNames[i], key);

        if (translation !== undefined) {
          break;
        }
      }

      return translation;
    },

    /** @private **/
    lookupAst(key, localeName, options = {}) {
      const localeNames = this._localeWithDefault(localeName);

      let translation;

      for (let i = 0; i < localeNames.length; i++) {
        translation = this._translationContainer.lookupAst(localeNames[i], key);

        if (translation !== undefined) {
          break;
        }
      }

      if (translation === undefined && options.resilient !== true) {
        const missingMessage = this._owner.resolveRegistration('util:intl/missing-message');

        return missingMessage.call(this, key, localeNames, options);
      }

      return translation;
    },

    validateKeys(keys) {
      return keys.forEach(key => {
        (false && !(typeof key === 'string') && Ember.assert(`[ember-intl] expected translation key "${key}" to be of type String but received: "${typeof key}"`, typeof key === 'string'));
      });
    },

    /** @public **/
    t(key, options = {}) {
      let keys = [key];

      if (options.default) {
        if (Array.isArray(options.default)) {
          keys = [...keys, ...options.default];
        } else if (typeof options.default === 'string') {
          keys = [...keys, options.default];
        }
      }

      this.validateKeys(keys);

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        const ast = this.lookupAst(key, options.locale, { ...options,
          // Note: last iteration will throw with the last key that was missing
          // in the future maybe the thrown error should include all the keys to help debugging
          resilient: keys.length - 1 !== index
        });

        if (ast) {
          return this.formatMessage(ast, options);
        }
      }
    },

    /** @public **/
    exists(key, localeName) {
      const localeNames = this._localeWithDefault(localeName);

      (false && !(Array.isArray(localeNames) && localeNames.length) && Ember.assert(`[ember-intl] locale is unset, cannot lookup '${key}'`, Array.isArray(localeNames) && localeNames.length));
      return localeNames.some(localeName => this._translationContainer.has(localeName, key));
    },

    /** @public */
    setLocale(locale) {
      Ember.set(this, 'locale', locale);
    },

    /** @public **/
    addTranslations(localeName, payload) {
      this._translationContainer.push((0, _normalizeLocale.default)(localeName), payload);
    },

    /** @public **/
    translationsFor(localeName) {
      return this._translationContainer.findTranslationModel((0, _normalizeLocale.default)(localeName), false);
    },

    /** @private **/
    _localeWithDefault(localeName) {
      if (!localeName) {
        return Ember.get(this, '_locale') || [];
      }

      if (typeof localeName === 'string') {
        return Ember.makeArray(localeName).map(_normalizeLocale.default);
      }

      if (Array.isArray(localeName)) {
        return localeName.map(_normalizeLocale.default);
      }
    },

    /** @private **/
    _updateDocumentLanguage(locales) {
      const dom = (0, _getDom.default)(this);

      if (dom) {
        const [primaryLocale] = locales;
        const html = dom.documentElement;
        html.setAttribute('lang', primaryLocale);
      }
    },

    /** @private */
    _createFormatters() {
      const formatterConfig = {
        onError: this.onError.bind(this),
        readFormatConfig: () => this.formats
      };
      return {
        message: new _formatters.FormatMessage(formatterConfig),
        relative: new _formatters.FormatRelative(formatterConfig),
        number: new _formatters.FormatNumber(formatterConfig),
        time: new _formatters.FormatTime(formatterConfig),
        date: new _formatters.FormatDate(formatterConfig)
      };
    }

  });

  _exports.default = _default;

  function createFormatterProxy(name) {
    return function serviceFormatterProxy(value, formatOptions) {
      let locale;

      if (formatOptions && formatOptions.locale) {
        locale = this._localeWithDefault(formatOptions.locale);
      } else {
        locale = Ember.get(this, 'locale');
      }

      return this._formatters[name].format(locale, value, formatOptions);
    };
  }
});