define("ember-google-maps/components/g-map/route", ["exports", "ember-google-maps/components/g-map/typical-map-component"], function (_exports, _typicalMapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash directions=@directions)}}
  */
  {
    "id": "iuzdPTiq",
    "block": "{\"symbols\":[\"@directions\",\"&default\"],\"statements\":[[18,2,[[30,[36,0],null,[[\"directions\"],[[32,1]]]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "ember-google-maps/components/g-map/route.hbs"
  });

  class Route extends _typicalMapComponent.default {
    get name() {
      return 'routes';
    }

    get newOptions() {
      var _this$options$directi;

      if (((_this$options$directi = this.options.directions) === null || _this$options$directi === void 0 ? void 0 : _this$options$directi.status) !== 'OK') {
        return {};
      }

      return this.options;
    }

    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.DirectionsRenderer(options);
    }

  }

  _exports.default = Route;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Route);
});