define("@streethub/mirage-api/resources/curate-order", ["exports", "@streethub/mirage-api/utils/request", "faker"], function (_exports, _request, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null; // Wrap find logic here because we can also find resources
    // using the X-HTTP-Method-Override = GET header

    function findResources(schema, query) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, query); // Return data

      return schema.curateOrders.where(queryParams);
    } // Routes


    server.get('orders', function (schema, request) {
      return findResources(schema, request.queryParams);
    });
    server.get('orders/:id', function (schema, request) {
      const id = request.params.id;
      const curateOrder = schema.curateOrders.find(id);

      if (!curateOrder) {
        return schema.curateOrders.none();
      }

      return curateOrder;
    });
    server.post('orders', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      const order = schema.curateOrders.create(requestBody);

      const currency = _faker.default.random.arrayElement(['GBP', 'EUR']);

      const total_price = order.total_price ? order.total_price : {
        amount: 100000,
        currency
      };
      const _id = order.id;
      order.update({
        _id,
        total_price
      });
      return order;
    });
    server.put('orders/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.curateOrders.update(id, body);
    });
    server.del('orders/:id', function (schema, request) {
      const id = request.params.id;
      const curateOrder = schema.curateOrders.find(id);
      return curateOrder.destroy();
    });
  }
});