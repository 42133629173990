define("@streethub/mirage-api/resources/packaging-product", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('products', function (schema, request) {
      const {
        queryParams: {
          variants_country,
          variants_sku,
          ...restQueryParams
        }
      } = request;
      const queryParams = (0, _request.normalizeQueryParams)(schema, restQueryParams);
      let products = schema.packagingProducts.where(queryParams);

      if (variants_country) {
        products = products.filter(_ref => {
          let {
            variants
          } = _ref;
          return variants.find(_ref2 => {
            let {
              country
            } = _ref2;
            return country === variants_country;
          });
        });
      }

      if (variants_sku) {
        products = products.filter(_ref3 => {
          let {
            variants
          } = _ref3;
          return variants.find(_ref4 => {
            let {
              sku
            } = _ref4;
            return sku === variants_sku;
          });
        });
      }

      return products;
    });
    server.get('products/:id', function (schema, request) {
      const id = request.params.id;
      const packagingProduct = schema.packagingProducts.find(id);

      if (!packagingProduct) {
        return schema.packagingProducts.none();
      }

      return packagingProduct;
    });
    server.post('products', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.packagingProducts.create(requestBody);
    });
    server.put('products/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.packagingProducts.update(id, body);
    });
  }
});