define("ember-svg-jar/inlined/cc-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#00579f\" d=\"M433.35 318.98h-81L403 5.68h81zM727.02 13.34C711.04 7 685.7 0 654.36 0c-80 0-136.33 42.66-136.68 103.65-.66 45 40.34 70 71 85 31.34 15.33 42 25.34 42 39-.32 21-25.33 30.67-48.65 30.67-32.35 0-49.68-4.99-76.01-16.67l-10.67-5-11.34 70.33c19 8.65 54 16.34 90.35 16.67 85 0 140.34-42 141-107 .32-35.66-21.33-62.99-68-85.32-28.33-14.33-45.69-24-45.69-38.67.34-13.33 14.68-26.99 46.66-26.99 26.34-.67 45.69 5.66 60.35 12l7.33 3.32 11.01-67.65zM834.7 208c6.67-18 32.34-87.67 32.34-87.67-.34.67 6.66-18.33 10.66-30l5.66 27s15.34 75 18.67 90.66h-67.34zm100-202.32h-62.67c-19.32 0-34 5.66-42.34 26l-120.33 287.3h85s14-38.67 17-47h104.01c2.33 11 9.67 47 9.67 47h75L934.7 5.68zm-650.02 0l-79.34 213.64-8.67-43.33C182.01 126 136 71.68 84.67 44.67l72.67 273.98H243L370.34 5.68h-85.66z\"/><path fill=\"#faa61a\" d=\"M131.67 5.68H1.33L0 12c101.67 26 169 88.67 196.67 164L168.34 32c-4.67-20-19-25.67-36.67-26.33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000.05 323.65"
    }
  };
  _exports.default = _default;
});