define("@streethub/mirage-api/resources/stock-line", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    context.optionalData = null;

    function findResources(schema, query) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, query);
      return schema.stockLines.where(queryParams);
    }

    server.get('stock_lines', function (schema, request) {
      return findResources(schema, request.queryParams);
    });
    server.get('stock_lines/:id', function (schema, request) {
      const id = request.params.id;
      const stockLine = schema.stockLines.find(id);

      if (!stockLine) {
        return schema.stockLines.none();
      }

      return stockLine;
    });
    server.post('stock_lines', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.stockLines.create(requestBody);
    });
    server.put('stock_lines/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.stockLines.update(id, body);
    });
  }
});