define("@streethub/mirage-api/factories/zone", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Europe',

    _id() {
      return this.id;
    },

    countries() {
      return [];
    }

  });

  _exports.default = _default;
});