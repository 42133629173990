define("ember-wormhole/templates/components/ember-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JY/UZphM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,0],[[32,0,[\"_wormholeHeadNode\"]]],null]],[18,1,null],[1,[30,[36,0],[[32,0,[\"_wormholeTailNode\"]]],null]]],\"hasEval\":false,\"upvars\":[\"unbound\"]}",
    "moduleName": "ember-wormhole/templates/components/ember-wormhole.hbs"
  });

  _exports.default = _default;
});