define("@streethub/mirage-api/utils/object-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Fake ObjectID generator:
  // https://stackoverflow.com/questions/10593337/is-there-any-way-to-create-mongodb-like-id-strings-without-mongodb
  function objectId() {
    return hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16));
  }

  function hex(value) {
    return Math.floor(value).toString(16);
  }

  var _default = objectId;
  _exports.default = _default;
});