define("@streethub/mirage-api/resources/user", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('users', function (schema, request) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, request.queryParams);
      return schema.users.where(queryParams);
    });
    server.get('users/:id', function (schema, request) {
      const id = request.params.id;
      const user = schema.users.find(id);

      if (!user) {
        return schema.users.none();
      }

      return user;
    });
    server.post('users', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.users.create(requestBody);
    });
    server.put('users/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.users.update(id, body);
    });
    server.del('users/:id', function (schema, request) {
      const id = request.params.id;
      const user = schema.users.find(id);
      return user.destroy();
    });
  }
});