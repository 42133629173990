define("@adopted-ember-addons/ember-stripe-elements/components/stripe-elements", ["exports", "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-elements"], function (_exports, _stripeElements) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stripe: Ember.inject.service('stripev3'),
    tagName: '',
    layout: _stripeElements.default,

    init() {
      this._super(...arguments);

      let options = Ember.get(this, 'options') || {};
      let elements = Ember.get(this, 'stripe').elements(options);
      Ember.set(this, 'elements', elements);
    }

  });

  _exports.default = _default;
});