define("@streethub/mirage-api/factories/packaging-product", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getDimension = unit => ({
    value: _faker.default.datatype.number({
      min: 1,
      max: 500
    }),
    unit
  });

  const getDimensions = () => {
    let unit = _faker.default.random.arrayElement(['cm', 'mm']);

    return {
      width: getDimension(unit),
      height: getDimension(unit),
      length: getDimension(unit)
    };
  };

  const getVariant = function () {
    let {
      currency = 'GBP',
      out_of_stock = false
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return {
      sku: _faker.default.random.word(),
      dimensions: getDimensions(),
      pack: {
        price: {
          amount: _faker.default.datatype.number({
            min: 1000,
            max: 99999
          }),
          currency
        },
        units: _faker.default.datatype.number({
          min: 10,
          max: 250
        })
      },
      color: _faker.default.random.word(),
      third_party_id: _faker.default.datatype.uuid(),
      out_of_stock,
      country: '1'
    };
  };

  const getImage = () => ({
    url: _faker.default.image.image()
  });

  var _default = _emberCliMirage.Factory.extend({
    status: 'published',

    name() {
      return _faker.default.commerce.productName();
    },

    description() {
      return _faker.default.lorem.paragraph();
    },

    variants() {
      const currency = _faker.default.random.arrayElement(['EUR', 'GBP']);

      return [getVariant({
        currency
      }), getVariant({
        currency
      })];
    },

    images() {
      return [getImage()];
    },

    out_of_stock_variants_count() {
      return this.variants.reduce((outOfStock, variant) => {
        return variant.out_of_stock ? ++outOfStock : outOfStock;
      }, 0);
    },

    archived: (0, _emberCliMirage.trait)({
      status: 'archived'
    }),
    partiallyOutOfStock: (0, _emberCliMirage.trait)({
      variants() {
        const currency = _faker.default.random.arrayElement(['EUR', 'GBP']);

        return [getVariant({
          currency
        }), getVariant({
          currency,
          out_of_stock: true
        })];
      }

    }),
    outOfStock: (0, _emberCliMirage.trait)({
      variants() {
        const currency = _faker.default.random.arrayElement(['EUR', 'GBP']);

        const properties = {
          currency,
          out_of_stock: true
        };
        return [getVariant(properties), getVariant(properties)];
      }

    }),

    afterCreate(product) {
      product.update({
        _id: product.id
      });
    }

  });

  _exports.default = _default;
});