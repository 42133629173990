define("ember-svg-jar/inlined/monogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#000\" d=\"M0 0h768v768H0z\"/><path d=\"M455 229.2c-35.2 0-58.9 27.5-58.9 62.4l.3 247.6h34.4l.3-241c-.3-34.5 16.5-38 26-38l81.6.4v-31.4H455zm-143.1 0c35.2 0 58.9 27.5 58.9 62.4l-.3 247.6h-34.4l-.3-241c.3-34.5-16.5-38-26-38l-81.3.4v-31.4h83.4z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 768 768"
    }
  };
  _exports.default = _default;
});