define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "access-point-modal": "__a4f46",
    "banner": "__2e204",
    "boutique-restock-information-modal/metrics": "__9df90",
    "boutique-restock-information-modal/product": "__7704e",
    "boutique-restock-information-modal": "__e4656",
    "boutique-restock-recommendation-scroll/card": "__4b94b",
    "boutique-restock-recommendation-scroll": "__1cb8d",
    "boutique-restock-recommendation-stock-status": "__5b56d",
    "boutique-restock-recommendation-table/row": "__f792a",
    "boutique-restock-recommendation-table": "__a0bdf",
    "boutique-setup-progress": "__ee83c",
    "bulk-edit-footer": "__20300",
    "checkbox": "__6ef9f",
    "confirmation-modal": "__e647a",
    "curate-basket-toast": "__6f20b",
    "curate-brand-card": "__3116d",
    "curate-brand-header": "__2ca2c",
    "curate-brand-referral-modal": "__1e702",
    "curate-categories-filter": "__a60fe",
    "curate-categories-filter/subtree": "__07322",
    "curate-empty-basket": "__e741d",
    "curate-header": "__7e79e",
    "curate-order-summary": "__5fe60",
    "curate-product-card/modal": "__6f0fd",
    "curate-product-card": "__4c486",
    "curate-tags-filter": "__ac400",
    "date-select": "__bdff9",
    "discard-changes-modal": "__e06bb",
    "discount-modal": "__5aa59",
    "ember-power-select-no-matches": "__3d751",
    "empty-page": "__4c421",
    "feature-flags-manager": "__7ed78",
    "form-cloudinary": "__eee11",
    "form-flows/error-group": "__98572",
    "form-flows/input": "__ec149",
    "form-flows/product/description-details": "__f0561",
    "form-flows/product/gallery-details/edit-modal": "__03065",
    "form-flows/product/product-options/option": "__346dd",
    "form-flows/product/product-options": "__0a018",
    "form-flows/product": "__56a08",
    "form-flows/shipment/customer-details": "__fb7c3",
    "form-flows/shipment/history": "__9d2f5",
    "form-flows/shipment/order-details": "__c94f8",
    "form-flows/shipment/pickup-details": "__996a2",
    "form-flows/shipment/quote": "__6b179",
    "form-flows/shipment/shipment-cost": "__122d1",
    "form-holidays": "__02da9",
    "form-opening-hours": "__89f1b",
    "form-status": "__46910",
    "frame-form/common": "__118fa",
    "frame-form/lifestyle": "__aa2ce",
    "frame-form/product": "__7d522",
    "frame-form/spotify": "__c5d00",
    "frame-form": "__c29a2",
    "frame-form/tags": "__45db2",
    "frequently-asked-questions": "__c7aed",
    "gift-wrap-modal": "__8740f",
    "horizontal-scroll": "__e4d18",
    "how-to": "__5fefb",
    "image-uploader": "__92691",
    "inline-editor": "__8e7c5",
    "input-affixed": "__e8083",
    "input-price": "__91725",
    "input-range": "__15974",
    "input-search": "__16aca",
    "input-text": "__c1a2e",
    "insights-educational-modal": "__038e3",
    "lightbox": "__06d36",
    "load-more": "__85beb",
    "made-order-modal": "__050c6",
    "made-reservation-actions-modal": "__1e473",
    "main-nav": "__77cfa",
    "manufacturer-suggestion": "__a0c03",
    "metabase-embed": "__7026d",
    "nav-bar-notification": "__a0adf",
    "nav-bar": "__01a0b",
    "new-label": "__11caa",
    "notification": "__7971d",
    "oi-alternative": "__3d5d9",
    "other-actions": "__f9545",
    "packaging-banner": "__ef421",
    "packaging-basket": "__ab851",
    "packaging-how-to": "__7c0b4",
    "packaging-placeholder-image": "__e1b35",
    "packaging-product-card": "__dd139",
    "packaging-products-table": "__20c55",
    "pages/curate/brands/brand/basket-page": "__9def3",
    "pages/curate/brands/brand/checkout/information-page": "__ccb9b",
    "pages/curate/brands/brand/index-page": "__e6d8c",
    "pages/curate/index-page": "__c136b",
    "pages/curate/orders/index": "__20193",
    "pages/curate/orders/submitted": "__653be",
    "pages/curate/terms": "__2996f",
    "pages/dashboard/index-page": "__cc7f8",
    "pages/dashboard": "__1dd21",
    "pages/frames/empty": "__d70a3",
    "pages/frames/index": "__8a844",
    "pages/insights": "__fee6e",
    "pages/inventory-index": "__2d1e2",
    "pages/join": "__4d50b",
    "pages/landing": "__82225",
    "pages/login-page": "__5ec18",
    "pages/packaging/basket": "__6ba1d",
    "pages/packaging/index": "__d4d00",
    "pages/packaging/orders/confirmation": "__6bc97",
    "pages/packaging/orders/index": "__8e489",
    "pages/packaging/orders/order": "__59685",
    "pages/packaging/products/index": "__87259",
    "pages/packaging/products/product": "__72cb1",
    "pages/packaging/products": "__c4000",
    "pages/products/bulk/index": "__5427a",
    "pages/products/bulk/upload/amend-step/brand-amend-modal": "__1d54e",
    "pages/products/bulk/upload/amend-step/sku-amend-modal": "__8d020",
    "pages/products/bulk/upload/amend-step": "__6014d",
    "pages/products/bulk/upload/amend-step/variants-amend-modal": "__4dbe9",
    "pages/products/bulk/upload/review-step": "__5ab49",
    "pages/products/bulk/upload": "__1c7f7",
    "pages/products/bulk/upload/upload-step": "__b74c1",
    "pages/products/handsfree-bulk-upload": "__a0bb0",
    "pages/products/index-page": "__1078b",
    "pages/products/manual-bulk-upload": "__42230",
    "pages/products/new": "__abbf8",
    "pages/settings/holiday-mode": "__32330",
    "pages/settings/index": "__d51e1",
    "pages/settings/operational-status": "__c9e9f",
    "pages/settings/order-fulfilment": "__2e081",
    "pages/settings": "__896c3",
    "pages/shipments/index-page": "__7d866",
    "pages/shipments/new": "__20e7d",
    "pages/statements/eu-vat": "__ddd5a",
    "pages/statements/index": "__734d8",
    "pages/terms-and-conditions-index": "__cc06f",
    "pages/users/index": "__66c7e",
    "pages/users/new": "__84677",
    "pages/welcome": "__b62da",
    "pages/your-contract": "__d939c",
    "pagination": "__fa24c",
    "pause-mode-modal": "__9d1de",
    "price": "__52ac4",
    "product-amendment-card": "__828dd",
    "progress-bar": "__6f60d",
    "radio-button": "__b2cd5",
    "reservation-product-gift": "__5c02c",
    "reservation-product-header": "__d2d9a",
    "reservation-product/active": "__369d4",
    "rp-modals/shopify-sync-started": "__0139f",
    "selected-for-made-banner": "__53f18",
    "selected-for-made-label": "__f2084",
    "shipment-rate-calculator": "__fc3dd",
    "star-boutique-label": "__da159",
    "statement-item": "__8c192",
    "stock-line-input": "__b04a2",
    "stock-line-status": "__de359",
    "tag": "__80fa5",
    "tip-box": "__29dfa",
    "tooltip": "__44888",
    "truncate": "__51e5c",
    "under-maintenance": "__4687e",
    "zendesk-redirect": "__26f20"
  };
  _exports.default = _default;
});