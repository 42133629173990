define("@streethub/mirage-api/utils/records", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paginateRecords = paginateRecords;
  _exports.sortRecords = sortRecords;

  function sortArrayByProperty(array, sortPropertyName, sortAsc) {
    return array.sort((a, b) => {
      if (sortAsc) {
        return a[sortPropertyName] > b[sortPropertyName];
      }

      return a[sortPropertyName] < b[sortPropertyName];
    });
  }

  function sortRecords(sort, records) {
    const sortAscending = sort.indexOf('-') !== 0;
    const sortProperty = sortAscending ? sort : sort.substr(1);
    return sortArrayByProperty(records, sortProperty, sortAscending);
  }

  function paginateRecords(page, limit, records) {
    return records.slice(limit * (page - 1), limit * page);
  }
});