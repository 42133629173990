define('@fortawesome/pro-light-svg-icons', ['exports'], function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   */
  var faBoxFull = {
    prefix: 'fal',
    iconName: 'box-full',
    icon: [640, 512, [], "f49c", "M638.3 239.8L586.8 137c-2.8-5.6-8.5-9-14.6-9-1 0 4.3-.7-56.3 7l26.2-71.8c6-16.6-2.5-35-19.1-41L467.3 1.9c-16.5-6-34.9 2.5-41 19.1l-42.8 117.7C380.7 61.7 317.7 0 240 0 164.7 0 103.7 58 97.3 131.6 67.8 127.8 69 128 67.8 128c-6.1 0-11.8 3.5-14.6 9L1.7 239.8c-4.6 9.2.3 20.2 10.1 23L64 277.7V425c0 14.7 10 27.5 24.2 31l216.2 54.1c13.6 3.4 25 1.5 31 0L551.8 456c14.2-3.6 24.2-16.4 24.2-31V277.7l52.1-14.9c9.9-2.8 14.7-13.8 10.2-23zM456.4 32L512 52.2l-31.8 87.3-66 8.4L456.4 32zM38.8 237.3l38-76 190.4 24.3-60.1 99.8-168.3-48.1zM304 477L96 425V286.9C219.3 322.1 211 320 214.3 320c5.6 0 11-2.9 14-7.9L304 186.5V477zm16-317c-95.7-12.2-154.6-19.7-191.1-24.4C133.2 77.8 181.1 32 240 32c61.8 0 112 50.2 112 112 0 4.1-.6 8.1-1.1 12.1L320 160zm224 265l-208 52V186.5L411.7 312c3 5 8.4 7.9 14 7.9 3.3 0-5.2 2.1 118.3-33.1V425zM432.9 285.3l-60.1-99.8 190.4-24.3 38 76-168.3 48.1z"]
  };
  var faHandHoldingUsd = {
    prefix: 'fal',
    iconName: 'hand-holding-usd',
    icon: [576, 512, [], "f4c0", "M256.7 135.7l56.4 16.1c8.8 2.5 14.9 10.6 14.9 19.7 0 11.3-9.2 20.5-20.5 20.5h-36.9c-8.2 0-16.1-2.6-22.6-7.3-3-2.2-7.2-1.5-9.8 1.2l-11.4 11.4c-3.5 3.5-2.9 9.2 1 12.2 12.3 9.4 27.2 14.5 42.9 14.5h1.4v24c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-24h1.4c22.8 0 44.3-13.6 51.7-35.2 10.1-29.6-7.3-59.8-35.1-67.8L263 104.1c-8.8-2.5-14.9-10.6-14.9-19.7 0-11.3 9.2-20.5 20.5-20.5h36.9c8.2 0 16.1 2.6 22.6 7.3 3 2.2 7.2 1.5 9.8-1.2l11.4-11.4c3.5-3.5 2.9-9.2-1-12.2C336 37.1 321.1 32 305.4 32H304V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v24h-3.5c-30.6 0-55.1 26.3-52.2 57.5 2 22.1 19 40.1 40.4 46.2zm301.6 197.9c-19.7-17.7-49.4-17.6-69.9-1.2l-61.6 49.3c-1.9 1.5-4.2 2.3-6.7 2.3h-41.6c4.6-9.6 6.5-20.7 4.8-32.3-4-27.9-29.6-47.7-57.8-47.7H181.3c-20.8 0-41 6.7-57.6 19.2L85.3 352H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88l46.9-35.2c11.1-8.3 24.6-12.8 38.4-12.8H328c13.3 0 24 10.7 24 24s-10.7 24-24 24h-88c-8.8 0-16 7.2-16 16s7.2 16 16 16h180.2c9.7 0 19.1-3.3 26.7-9.3l61.6-49.2c7.7-6.1 20-7.6 28.4 0 10.1 9.1 9.3 24.5-.9 32.6l-100.8 80.7c-7.6 6.1-17 9.3-26.7 9.3H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h400.5c17 0 33.4-5.8 46.6-16.4L556 415c12.2-9.8 19.5-24.4 20-40s-6-30.8-17.7-41.4z"]
  };
  var faLeaf = {
    prefix: 'fal',
    iconName: 'leaf',
    icon: [576, 512, [], "f06c", "M546.2 9.7c-2.9-6.5-8.6-9.7-14.3-9.7-5.3 0-10.7 2.8-14 8.5C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 20.9 3.4 40.9 9.6 59.7C29.3 413 1.4 489.4.9 490.7c-2.9 8.3 1.5 17.5 9.8 20.4 7.9 2.8 17.4-1.1 20.4-9.8.4-1.2 23.9-65.1 87.6-122.7C151.1 438.9 214.7 480 288 480c6.9 0 13.7-.4 20.4-1.1C465.5 467.5 576 326.8 576 154.3c0-50.2-10.8-102.2-29.8-144.6zM305 447.1c-5.9.6-11.6.9-17 .9-63.3 0-117.6-37.2-143.5-90.6C196.3 319 268.6 288 368 288c8.8 0 16-7.2 16-16s-7.2-16-16-16c-102.8 0-179 31-234.8 70.4-3.1-12.4-5.2-25.1-5.2-38.4 0-88.2 71.8-160 160-160h80c63.3 0 121-28.4 159.7-77.2 10.5 32.3 16.3 68.7 16.3 103.5 0 159.6-100.1 282.7-239 292.8z"]
  };
  var faLongArrowRight = {
    prefix: 'fal',
    iconName: 'long-arrow-right',
    icon: [448, 512, [], "f178", "M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"]
  };
  var faPaperclip = {
    prefix: 'fal',
    iconName: 'paperclip',
    icon: [512, 512, [], "f0c6", "M149.106 512c-33.076 0-66.153-12.59-91.333-37.771-50.364-50.361-50.364-132.305-.002-182.665L319.842 29.498c39.331-39.331 103.328-39.331 142.66 0 39.331 39.332 39.331 103.327 0 142.657l-222.63 222.626c-28.297 28.301-74.347 28.303-102.65 0-28.3-28.301-28.3-74.349 0-102.649l170.301-170.298c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971l-170.3 170.297c-15.821 15.821-15.821 41.563.001 57.385 15.821 15.82 41.564 15.82 57.385 0l222.63-222.626c26.851-26.851 26.851-70.541 0-97.394-26.855-26.851-70.544-26.849-97.395 0L80.404 314.196c-37.882 37.882-37.882 99.519 0 137.401 37.884 37.881 99.523 37.882 137.404.001l217.743-217.739c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971L240.44 474.229C215.26 499.41 182.183 512 149.106 512z"]
  };
  var faPencilAlt = {
    prefix: 'fal',
    iconName: 'pencil-alt',
    icon: [512, 512, [], "f303", "M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zM164.686 347.313c6.249 6.249 16.379 6.248 22.627 0L368 166.627l30.059 30.059L174 420.745V386h-48v-48H91.255l224.059-224.059L345.373 144 164.686 324.687c-6.249 6.248-6.249 16.378 0 22.626zm-38.539 121.285l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"]
  };
  var faShippingFast = {
    prefix: 'fal',
    iconName: 'shipping-fast',
    icon: [640, 512, [], "f48b", "M280 192c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240zm352 192h-24V275.9c0-16.8-6.8-33.3-18.8-45.2l-83.9-83.9c-11.8-12-28.3-18.8-45.2-18.8H416V78.6c0-25.7-22.2-46.6-49.4-46.6H113.4C86.2 32 64 52.9 64 78.6V96H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H96V78.6c0-8.1 7.8-14.6 17.4-14.6h253.2c9.6 0 17.4 6.5 17.4 14.6V384H207.6C193 364.7 170 352 144 352c-18.1 0-34.6 6.2-48 16.4V288H64v144c0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16h195.2c-1.1 5.2-1.6 10.5-1.6 16 0 44.2 35.8 80 80 80s80-35.8 80-80c0-5.5-.6-10.8-1.6-16H632c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-488 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm272-320h44.1c8.4 0 16.7 3.4 22.6 9.4l83.9 83.9c.8.8 1.1 1.9 1.8 2.8H416V160zm80 320c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-96h-16.4C545 364.7 522 352 496 352s-49 12.7-63.6 32H416v-96h160v96zM256 248v-16c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8z"]
  };
  var faStars = {
    prefix: 'fal',
    iconName: 'stars',
    icon: [512, 512, [], "f762", "M509.05273,219.03853l-39.71289-16.56934-16.55859-39.73866a5.32506,5.32506,0,0,0-9.541,0l-16.56054,39.73866-39.71094,16.56934a5.33947,5.33947,0,0,0,0,9.54721L426.67969,245.157l16.56054,39.73671a5.3235,5.3235,0,0,0,9.541,0L469.33984,245.157l39.71289-16.57129a5.33947,5.33947,0,0,0,0-9.54721ZM364.30049,267.08,259.38106,251.77833,212.46892,156.549a22.735,22.735,0,0,0-20.59634-12.7878A22.40581,22.40581,0,0,0,171.37,156.549l-46.91214,95.22933L19.6322,267.08C.84859,269.76557-6.65235,293.06155,6.94311,306.349l75.91578,74.05684L64.85663,485.01914A23.00313,23.00313,0,0,0,87.45322,512a22.60025,22.60025,0,0,0,10.68884-2.6856l93.82428-49.37123,93.82429,49.37123A23.38328,23.38328,0,0,0,296.38571,512a23.09547,23.09547,0,0,0,22.69035-26.88718l-17.9085-104.61329,75.91578-74.05685C390.6788,293.06155,383.0841,269.87487,364.30049,267.08ZM266.78824,369.28873l17.596,103.14558-92.51162-48.7779L99.361,472.43431l17.596-103.14558L42.26,296.1531l103.388-15.08305,46.31831-93.83969,46.19331,93.83969L341.57888,296.1531ZM423.1875,81.33009,374.59375,57.01744,350.3125,8.17327c-5.40625-10.89769-23.21875-10.89769-28.625,0L297.40625,56.81419,248.8125,81.12683A16.04689,16.04689,0,0,0,240,95.433a16.46336,16.46336,0,0,0,8.8125,14.50941L297.40625,134.255,321.6875,182.896A16.07056,16.07056,0,0,0,336,191.79235a15.87374,15.87374,0,0,0,14.3125-8.8964L374.59375,134.255l48.59375-24.31264a16.0185,16.0185,0,0,0,0-28.6123ZM355.5,107.94109a16.19316,16.19316,0,0,0-7.1875,7.2078L336,139.86805l-12.3125-24.71916a16.75685,16.75685,0,0,0-7.1875-7.2078L291.8125,95.54243,316.5,83.12813a16.11584,16.11584,0,0,0,7.1875-7.2078L336,51.20117l12.3125,24.71916a16.59161,16.59161,0,0,0,7.1875,7.2078l24.6875,12.4143Z"]
  };
  var faTimes = {
    prefix: 'fal',
    iconName: 'times',
    icon: [320, 512, [], "f00d", "M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"]
  };
  var faUser = {
    prefix: 'fal',
    iconName: 'user',
    icon: [448, 512, [], "f007", "M313.6 288c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4zM416 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 56.5 0 102.4 45.9 102.4 102.4V464zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"]
  };

  exports.faBoxFull = faBoxFull;
  exports.faHandHoldingUsd = faHandHoldingUsd;
  exports.faLeaf = faLeaf;
  exports.faLongArrowRight = faLongArrowRight;
  exports.faPaperclip = faPaperclip;
  exports.faPencilAlt = faPencilAlt;
  exports.faShippingFast = faShippingFast;
  exports.faStars = faStars;
  exports.faTimes = faTimes;
  exports.faUser = faUser;

  Object.defineProperty(exports, '__esModule', { value: true });

});
