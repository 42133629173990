define("@streethub/mirage-api/utils/ensure-factories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Mirage doesn't load factories when you are still setting up the API routes.
   * This leads to problems, like factories not generating data when trying to load
   * data within mirage-api resources.
   *
   * @param server        - Ember cli mirage server
   * @param factoryNames  - The names of factories to load
   */
  function _default(server) {
    let factoryNames = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    const factoriesToLoad = factoryNames.reduce(function (prev, factoryName) {
      // Load factory only if it's not been loaded yet
      if (!server.factoryFor(factoryName)) {
        prev[factoryName] = server._config.factories[factoryName];
      }

      return prev;
    }, {});
    server.loadFactories(factoriesToLoad);
  }
});