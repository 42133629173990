define("flaneur/mirage/models/curate-category", ["exports", "@streethub/mirage-api/models/curate-category"], function (_exports, _curateCategory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _curateCategory.default;
    }
  });
});