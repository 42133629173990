define("@streethub/mirage-api/factories/brand", ["exports", "ember-cli-mirage", "faker", "@streethub/mirage-api/utils/object-id"], function (_exports, _emberCliMirage, _faker, _objectId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shipping_enabled: true,
    shipping_small_item_price: 0,
    shipping_medium_item_price: 0,
    shipping_terms: '',

    created_at() {
      return _faker.default.date.past();
    },

    afterCreate(item, server) {
      // Set "_id" to be the same as mirage's "id"
      const propertiesToUpdate = {
        _id: item.id
      };

      if (!item.users) {
        // Automatically add all retail to this brand
        const retailUsers = server.db.users.where({
          role: 'retail'
        });
        propertiesToUpdate.users = retailUsers.map(user => ({
          _id: (0, _objectId.default)(),
          user: user._id
        }));
      }

      item.update(propertiesToUpdate);
    }

  });

  _exports.default = _default;
});