define("ember-power-select-with-create/components/power-select-with-create/suggested-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{@option.text}}
  
  */
  {
    "id": "ZPgQZD0O",
    "block": "{\"symbols\":[\"@option\"],\"statements\":[[1,[32,1,[\"text\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-power-select-with-create/components/power-select-with-create/suggested-option.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});