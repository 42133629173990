define("ember-svg-jar/inlined/curate-brand-referral-modal-shape-02", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".5\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.356 21.233c-2.37.34-5.04.11-6.961-1.253-1.89-1.341-2.348-3.66-2.903-5.747-.497-1.872-.618-3.753-.11-5.595C.909 6.726 1.877 4.973 3.425 3.6 5.16 2.06 7.19.028 9.642.42c2.495.4 2.957 3.647 5.04 4.982 2.074 1.33 5.69.59 6.832 2.632 1.116 1.992-.812 4.221-1.912 6.16-.919 1.62-2.322 2.843-3.862 4.016-1.653 1.258-3.222 2.713-5.384 3.023z\" fill=\"#F0E2DF\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});