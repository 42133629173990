define("@streethub/mirage-api/models/boutique", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    brand: (0, _emberCliMirage.belongsTo)(),
    address_country: (0, _emberCliMirage.belongsTo)('country'),
    registered_address_country: (0, _emberCliMirage.belongsTo)('country')
  });

  _exports.default = _default;
});