define('@streethub/ember-trouva-elements/components/trouva-elements/modal', ['exports', '@streethub/ember-trouva-elements/templates/components/trouva-elements/modal', '@streethub/ember-trouva-elements/default-to'], function (exports, _modal, _defaultTo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _modal.default,

    classNames: ['trouva-modal'],
    classNameBindings: ['active'],
    active: (0, _defaultTo.default)(false),

    actions: {
      dismiss() {
        this.set('active', false);
      }
    }
  });
});