define("@streethub/mirage-api/factories/boutique-restock-information", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    status: 'restocking',
    notes: '',

    quantity() {
      return _faker.default.datatype.number({
        min: 1,
        max: 50
      });
    },

    lead_date() {
      return _faker.default.date.future();
    },

    notAvailable: (0, _emberCliMirage.trait)({
      status: 'not-available',
      reason: {
        type: 'discontinued',
        notes: ''
      }
    }),
    restocked: (0, _emberCliMirage.trait)({
      status: 'restocked'
    }),

    afterCreate(item) {
      item.update({
        _id: item.id
      });
    }

  });

  _exports.default = _default;
});