define("@streethub/mirage-api/models/curate-brand", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Model.extend({
    tags: (0, _emberCliMirage.hasMany)('curate-brand-tag')
  });

  _exports.default = _default;
});