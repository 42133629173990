define("@streethub/mirage-api/resources/country", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('countries', function (schema, request) {
      const {
        'weengs_name[$regex]': weengsNameRegex,
        'weengs_name[$options]': weengsNameRegexOptions = 'i',
        ...queryParams
      } = (0, _request.normalizeQueryParams)(schema, request.queryParams);
      const countries = schema.countries.where(queryParams);

      if (!weengsNameRegex) {
        return countries;
      }

      const searchByWeengsNameRegex = new RegExp(weengsNameRegex, weengsNameRegexOptions);
      return countries.filter(_ref => {
        let {
          weengs_name
        } = _ref;
        return weengs_name.match(searchByWeengsNameRegex);
      });
    });
    server.get('countries/:id', function (schema, request) {
      const id = request.params.id;
      const country = schema.countries.find(id);

      if (!country) {
        return schema.countries.none();
      }

      return country;
    });
    server.post('countries', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.countries.create(requestBody);
    });
    server.put('countries/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.countries.update(id, body);
    });
  }
});