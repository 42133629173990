define("@streethub/mirage-api/resources/boutique-restock-information", ["exports", "@streethub/mirage-api/utils/request", "query-string"], function (_exports, _request, _queryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    context.optionalData = null;
    server.get('boutique_restock_information', function (schema, request) {
      const url = new URL(request.url);

      const {
        'product[$in]': productIn,
        ...restQueryParams
      } = _queryString.default.parse(url.search);

      const queryParams = (0, _request.normalizeQueryParams)(schema, restQueryParams);
      let boutiqueRestockInformation = schema.boutiqueRestockInformation.where(queryParams);

      if (productIn) {
        boutiqueRestockInformation = boutiqueRestockInformation.filter(_ref => {
          let {
            product
          } = _ref;
          return productIn.includes(product);
        });
      } // Populate products


      boutiqueRestockInformation.models.forEach(bri => {
        bri.product = schema.products.find(bri.product).attrs;
      });
      return boutiqueRestockInformation;
    });
    server.get('boutique_restock_information/:id', function (schema, request) {
      const id = request.params.id;
      const boutiqueRestockInformation = schema.boutiqueRestockInformation.find(id);

      if (!boutiqueRestockInformation) {
        return schema.boutiqueRestockInformation.none();
      }

      return boutiqueRestockInformation;
    });
    server.post('boutique_restock_information', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.boutiqueRestockInformation.create(requestBody);
    });
    server.put('boutique_restock_information/:id', function (schema, request) {
      const id = request.params.id;
      const boutiqueRestockInformation = schema.boutiqueRestockInformation.find(id);
      const body = JSON.parse(request.requestBody);

      if (!boutiqueRestockInformation) {
        return schema.boutiqueRestockInformation.none();
      }

      return boutiqueRestockInformation.update(body);
    });
  }
});