define("@streethub/mirage-api/factories/curate-product", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function createDefaultStockLine() {
    const unit = _faker.default.random.arrayElement(['cm', 'm']);

    const currency = _faker.default.random.arrayElement(['GBP', 'EUR']);

    const wholesaleAmount = _faker.default.datatype.number({
      min: 100,
      max: 420000
    });

    const retailAmount = wholesaleAmount + _faker.default.datatype.number({
      min: 10,
      max: 100
    });

    return {
      name: _faker.default.commerce.productName(),
      sku: _faker.default.lorem.word(),
      wholesale_price: {
        amount: wholesaleAmount,
        currency
      },
      retail_price: {
        amount: retailAmount,
        currency
      },
      images: Array.from(new Array(12).keys()).map(index => ({
        url: _faker.default.image.fashion(),
        display_order: index + 1
      })),
      dimensions: {
        width: {
          value: _faker.default.datatype.number({
            min: 1,
            max: 10
          }),
          unit
        },
        height: {
          value: _faker.default.datatype.number({
            min: 1,
            max: 10
          }),
          unit
        },
        length: {
          value: _faker.default.datatype.number({
            min: 1,
            max: 10
          }),
          unit
        }
      },
      pack_size: _faker.default.datatype.number({
        min: 1,
        max: 10
      }),
      quantity: _faker.default.datatype.number({
        min: 1,
        max: 50
      }),
      status: 'published'
    };
  }

  var _default = _emberCliMirage.Factory.extend({
    status: 'published',

    name() {
      return _faker.default.commerce.productName();
    },

    slug() {
      return Ember.String.dasherize(this.name);
    },

    stock_lines() {
      return [createDefaultStockLine()];
    },

    afterCreate(item) {
      // Set "_id" to be the same as mirage's "id"
      item.update({
        _id: item.id,
        stock_lines: item.stock_lines.map(stockLine => stockLine = { ...createDefaultStockLine(),
          ...stockLine
        })
      });
    }

  });

  _exports.default = _default;
});