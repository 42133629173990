define('@fortawesome/pro-regular-svg-icons', ['exports'], function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   */
  var faAbacus = {
    prefix: 'far',
    iconName: 'abacus',
    icon: [576, 512, [], "f640", "M552 0c-13.25 0-24 10.74-24 24v48h-48V48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24H240V48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24h-48V48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24H48V24C48 10.74 37.25 0 24 0S0 10.74 0 24v476c0 6.63 5.37 12 12 12h24c6.63 0 12-5.37 12-12v-60h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h192v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v60c0 6.63 5.37 12 12 12h24c6.63 0 12-5.37 12-12V24c0-13.26-10.75-24-24-24zM96 120v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h192v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v112H336v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24h-48v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24h-48v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24H48V120h48zm384 272v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24H240v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24h-48v-24c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v24H48V280h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h48v24c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-24h192v112h-48z"]
  };
  var faAlarmClock = {
    prefix: 'far',
    iconName: 'alarm-clock',
    icon: [512, 512, [], "f34e", "M256 64C132.26 64 32 164.29 32 288a222.69 222.69 0 0 0 44.75 134l-40.1 40.09a16 16 0 0 0 0 22.63l22.63 22.62a16 16 0 0 0 22.62 0l40.1-40.12a222.82 222.82 0 0 0 268 0l40.1 40.09a16 16 0 0 0 22.63 0l22.62-22.62a16 16 0 0 0 0-22.63L435.21 422A222.7 222.7 0 0 0 480 288c0-123.71-100.3-224-224-224zm0 400a176 176 0 1 1 176-176 176 176 0 0 1-176 176zM96 0A96 96 0 0 0 0 96a94.81 94.81 0 0 0 15.3 51.26L161.2 25.68A95.63 95.63 0 0 0 96 0zm184 292.47V168a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v132.16a32 32 0 0 0 12 25l64.54 51.57a8.58 8.58 0 0 0 5.87 1.72 8 8 0 0 0 5.35-2.95l20-25a8 8 0 0 0-1.25-11.27zM416 0a95.66 95.66 0 0 0-65.18 25.66l145.89 121.57A94.85 94.85 0 0 0 512 96a96 96 0 0 0-96-96z"]
  };
  var faAnalytics = {
    prefix: 'far',
    iconName: 'analytics',
    icon: [608, 512, [], "f643", "M416 320h-64c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zm-16 144h-32v-96h32v96zm176-272h-64c-17.67 0-32 14.33-32 32v256c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224c0-17.67-14.33-32-32-32zm-16 272h-32V240h32v224zM256 192h-64c-17.67 0-32 14.33-32 32v256c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V224c0-17.67-14.33-32-32-32zm-16 272h-32V240h32v224zM96 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zM80 464H48v-64h32v64zM64 256c26.51 0 48-21.49 48-48 0-4.27-.74-8.34-1.78-12.28l101.5-101.5C215.66 95.26 219.73 96 224 96c6.15 0 11.97-1.26 17.38-3.37l95.34 76.27c-.35 2.33-.71 4.67-.71 7.1 0 26.51 21.49 48 48 48s48-21.49 48-48c0-2.43-.37-4.76-.71-7.09l95.34-76.27C532.03 94.74 537.85 96 544 96c26.51 0 48-21.49 48-48S570.51 0 544 0s-48 21.49-48 48c0 2.43.37 4.76.71 7.09l-95.34 76.27c-5.4-2.11-11.23-3.37-17.38-3.37s-11.97 1.26-17.38 3.37L271.29 55.1c.35-2.33.71-4.67.71-7.1 0-26.51-21.49-48-48-48s-48 21.49-48 48c0 4.27.74 8.34 1.78 12.28l-101.5 101.5C72.34 160.74 68.27 160 64 160c-26.51 0-48 21.49-48 48s21.49 48 48 48z"]
  };
  var faAngleDown = {
    prefix: 'far',
    iconName: 'angle-down',
    icon: [320, 512, [], "f107", "M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"]
  };
  var faAngleUp = {
    prefix: 'far',
    iconName: 'angle-up',
    icon: [320, 512, [], "f106", "M168.5 164.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0L160 229.3 40.3 347.8c-4.7 4.7-12.3 4.7-17 0L3.5 328c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0z"]
  };
  var faArchive = {
    prefix: 'far',
    iconName: 'archive',
    icon: [512, 512, [], "f187", "M464 32H48C21.5 32 0 53.5 0 80v80c0 8.8 7.2 16 16 16h16v272c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V176h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-32 400H80V176h352v256zm32-304H48V80h416v48zM204 272h104c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12z"]
  };
  var faArrowLeft = {
    prefix: 'far',
    iconName: 'arrow-left',
    icon: [448, 512, [], "f060", "M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z"]
  };
  var faArrowRight = {
    prefix: 'far',
    iconName: 'arrow-right',
    icon: [448, 512, [], "f061", "M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"]
  };
  var faArrowsH = {
    prefix: 'far',
    iconName: 'arrows-h',
    icon: [512, 512, [], "f07e", "M347.404 142.86c-4.753 4.753-4.675 12.484.173 17.14l73.203 70H91.22l73.203-70c4.849-4.656 4.927-12.387.173-17.14l-19.626-19.626c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L128 388.766c4.686 4.686 12.284 4.686 16.971 0l19.626-19.626c4.753-4.753 4.675-12.484-.173-17.14L91.22 282h329.56l-73.203 70c-4.849 4.656-4.927 12.387-.173 17.14l19.626 19.626c4.686 4.686 12.284 4.686 16.971 0l124.485-124.281c4.686-4.686 4.686-12.284 0-16.971L384 123.234c-4.686-4.686-12.284-4.686-16.971 0l-19.625 19.626z"]
  };
  var faArrowsV = {
    prefix: 'far',
    iconName: 'arrows-v',
    icon: [320, 512, [], "f07d", "M273.1 347.4c-4.8-4.8-12.5-4.7-17.1.2l-70 73.2V91.2l70 73.2c4.7 4.8 12.4 4.9 17.1.2l19.6-19.6c4.7-4.7 4.7-12.3 0-17L168.5 3.5c-4.7-4.7-12.3-4.7-17 0L27.2 128c-4.7 4.7-4.7 12.3 0 17l19.6 19.6c4.8 4.8 12.5 4.7 17.1-.2l70-73.2v329.6l-70-73.2c-4.7-4.8-12.4-4.9-17.1-.2L27.2 367c-4.7 4.7-4.7 12.3 0 17l124.3 124.5c4.7 4.7 12.3 4.7 17 0L292.8 384c4.7-4.7 4.7-12.3 0-17l-19.7-19.6z"]
  };
  var faBars = {
    prefix: 'far',
    iconName: 'bars',
    icon: [448, 512, [], "f0c9", "M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z"]
  };
  var faBolt = {
    prefix: 'far',
    iconName: 'bolt',
    icon: [384, 512, [], "f0e7", "M377.8 167.9c-8.2-14.3-23.1-22.9-39.6-22.9h-94.4l28.7-87.5c3.7-13.8.8-28.3-7.9-39.7C255.8 6.5 242.5 0 228.2 0H97.7C74.9 0 55.4 17.1 52.9 37.1L.5 249.3c-1.9 13.8 2.2 27.7 11.3 38.2C20.9 298 34.1 304 48 304h98.1l-34.9 151.7c-3.2 13.7-.1 27.9 8.6 38.9 8.7 11.1 21.8 17.4 35.9 17.4 16.3 0 31.5-8.8 38.8-21.6l183.2-276.7c8.4-14.3 8.4-31.5.1-45.8zM160.1 457.4L206.4 256H47.5L97.7 48l127.6-.9L177.5 193H334L160.1 457.4z"]
  };
  var faBoxCheck = {
    prefix: 'far',
    iconName: 'box-check',
    icon: [640, 512, [], "f467", "M492.5 133.4L458.9 32.8C452.4 13.2 434.1 0 413.4 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-1.6 4.9-2.5 10-2.5 15.2V464c0 26.5 21.5 48 48 48h400c106 0 192-86 192-192 0-90.7-63-166.5-147.5-186.6zM280 48h133.4l26.8 80.4c-49.8 2-94.7 22.7-127.7 55.6H280V48zM98.6 48H232v136H53.3L98.6 48zM48 464V232h229.5c-13.6 26.4-21.5 56.3-21.5 88 0 57.4 25.3 108.8 65.3 144H48zm400 0c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144zm64.6-205.7c-3.1-3.1-8.1-3.1-11.2 0l-69.9 69.3-30.3-30.6c-3.1-3.1-8.1-3.1-11.2 0l-18.7 18.6c-3.1 3.1-3.1 8.1 0 11.2l54.4 54.9c3.1 3.1 8.1 3.1 11.2 0l94.2-93.5c3.1-3.1 3.1-8.1 0-11.2l-18.5-18.7z"]
  };
  var faBoxFull = {
    prefix: 'far',
    iconName: 'box-full',
    icon: [640, 512, [], "f49c", "M638.3 239.8L586.8 137c-4-8.1-12.1-9.5-16.7-8.9l-50.7 6.5L541.5 74c3.7-10 3.2-20.9-1.3-30.6-4.5-9.7-12.5-17-22.6-20.7L462.1 2.4c-20.7-7.6-43.7 3.2-51.3 23.9l-30.9 84.9C365 47.5 308.2 0 240 0 164.7 0 103.6 58 97.2 131.6l-27.4-3.5c-4.6-.6-12.6.9-16.7 8.9L1.7 239.8c-4.6 9.2.3 20.2 10.1 23L64 277.7V425c0 14.7 10 27.5 24.2 31l216.2 54.1c13.6 3.4 25 1.5 31 0L551.8 456c14.2-3.6 24.2-16.4 24.2-31V277.7l52.1-14.9c9.9-2.8 14.7-13.8 10.2-23zM453.2 50.3L493.7 65l-27.8 76.4-48.2 6.1 35.5-97.2zM61.7 227.2L86 178.6l154.8 19.7-41.2 68.3-137.9-39.4zM296 458.5l-184-46V291.4l97.8 27.9c8 2.3 15.2-1.8 18.5-7.3L296 199.8v258.7zm38.6-300.4L320 160l-175.4-22.3C148 87.7 189.2 48 240 48c52.9 0 96 43.1 96 96 0 4.8-.7 9.5-1.4 14.1zM528 412.5l-184 46V199.8l67.7 112.3c3.3 5.5 10.6 9.6 18.5 7.3l97.8-27.9v121zm-87.7-145.9l-41.2-68.3L554 178.6l24.3 48.6-138 39.4z"]
  };
  var faBoxUp = {
    prefix: 'far',
    iconName: 'box-up',
    icon: [512, 512, [], "f49f", "M400 368H112c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h288c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zM448 0H64C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v384zM358.2 99c-3.1-3.8-9.4-3.8-12.5 0l-64 80c-4.2 5.3-.4 13 6.2 13h32v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h32c6.7 0 10.4-7.7 6.2-13l-63.9-80zM128 192v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h32c6.7 0 10.4-7.7 6.2-13l-64-80c-3.1-3.8-9.4-3.8-12.5 0l-64 80c-4.2 5.3-.4 13 6.2 13H128z"]
  };
  var faBoxes = {
    prefix: 'far',
    iconName: 'boxes',
    icon: [640, 512, [], "f468", "M592 224H480V48c0-26.5-21.5-48-48-48H208c-26.5 0-48 21.5-48 48v176H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h544c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM208 48h64v90.7l48-21.3 48 21.3V48h64v176H208V48zm88 416H48V272h80v90.7l48-21.3 48 21.3V272h72v192zm296 0H344V272h72v90.7l48-21.3 48 21.3V272h80v192z"]
  };
  var faCalculator = {
    prefix: 'far',
    iconName: 'calculator',
    icon: [448, 512, [], "f1ec", "M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zm0 464H48V208h352v256zm0-304H48V48h352v112zM108.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm192 96h38.4c6.4 0 12.8-6.4 12.8-12.8V268.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm96-96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8zm0 96h38.4c6.4 0 12.8-6.4 12.8-12.8v-38.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v38.4c0 6.4 6.4 12.8 12.8 12.8z"]
  };
  var faCaretUp = {
    prefix: 'far',
    iconName: 'caret-up',
    icon: [320, 512, [], "f0d8", "M48.048 352h223.895c42.638 0 64.151-51.731 33.941-81.941l-111.943-112c-18.745-18.745-49.137-18.746-67.882 0l-111.952 112C-16.042 300.208 5.325 352 48.048 352zM160 192l112 112H48l112-112z"]
  };
  var faChartLine = {
    prefix: 'far',
    iconName: 'chart-line',
    icon: [512, 512, [], "f201", "M117.65 277.65c6.25 6.25 16.38 6.25 22.63 0L192 225.94l84.69 84.69c6.25 6.25 16.38 6.25 22.63 0L409.54 200.4l29.49 29.5c15.12 15.12 40.97 4.41 40.97-16.97V112c0-8.84-7.16-16-16-16H363.07c-21.38 0-32.09 25.85-16.97 40.97l29.5 29.49-87.6 87.6-84.69-84.69c-6.25-6.25-16.38-6.25-22.63 0l-74.34 74.34c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z"]
  };
  var faCheck = {
    prefix: 'far',
    iconName: 'check',
    icon: [512, 512, [], "f00c", "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"]
  };
  var faChevronDown = {
    prefix: 'far',
    iconName: 'chevron-down',
    icon: [448, 512, [], "f078", "M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"]
  };
  var faChevronLeft = {
    prefix: 'far',
    iconName: 'chevron-left',
    icon: [256, 512, [], "f053", "M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"]
  };
  var faChevronRight = {
    prefix: 'far',
    iconName: 'chevron-right',
    icon: [256, 512, [], "f054", "M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"]
  };
  var faChevronUp = {
    prefix: 'far',
    iconName: 'chevron-up',
    icon: [448, 512, [], "f077", "M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"]
  };
  var faCircle = {
    prefix: 'far',
    iconName: 'circle',
    icon: [512, 512, [], "f111", "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"]
  };
  var faCog = {
    prefix: 'far',
    iconName: 'cog',
    icon: [512, 512, [], "f013", "M452.515 237l31.843-18.382c9.426-5.441 13.996-16.542 11.177-27.054-11.404-42.531-33.842-80.547-64.058-110.797-7.68-7.688-19.575-9.246-28.985-3.811l-31.785 18.358a196.276 196.276 0 0 0-32.899-19.02V39.541a24.016 24.016 0 0 0-17.842-23.206c-41.761-11.107-86.117-11.121-127.93-.001-10.519 2.798-17.844 12.321-17.844 23.206v36.753a196.276 196.276 0 0 0-32.899 19.02l-31.785-18.358c-9.41-5.435-21.305-3.877-28.985 3.811-30.216 30.25-52.654 68.265-64.058 110.797-2.819 10.512 1.751 21.613 11.177 27.054L59.485 237a197.715 197.715 0 0 0 0 37.999l-31.843 18.382c-9.426 5.441-13.996 16.542-11.177 27.054 11.404 42.531 33.842 80.547 64.058 110.797 7.68 7.688 19.575 9.246 28.985 3.811l31.785-18.358a196.202 196.202 0 0 0 32.899 19.019v36.753a24.016 24.016 0 0 0 17.842 23.206c41.761 11.107 86.117 11.122 127.93.001 10.519-2.798 17.844-12.321 17.844-23.206v-36.753a196.34 196.34 0 0 0 32.899-19.019l31.785 18.358c9.41 5.435 21.305 3.877 28.985-3.811 30.216-30.25 52.654-68.266 64.058-110.797 2.819-10.512-1.751-21.613-11.177-27.054L452.515 275c1.22-12.65 1.22-25.35 0-38zm-52.679 63.019l43.819 25.289a200.138 200.138 0 0 1-33.849 58.528l-43.829-25.309c-31.984 27.397-36.659 30.077-76.168 44.029v50.599a200.917 200.917 0 0 1-67.618 0v-50.599c-39.504-13.95-44.196-16.642-76.168-44.029l-43.829 25.309a200.15 200.15 0 0 1-33.849-58.528l43.819-25.289c-7.63-41.299-7.634-46.719 0-88.038l-43.819-25.289c7.85-21.229 19.31-41.049 33.849-58.529l43.829 25.309c31.984-27.397 36.66-30.078 76.168-44.029V58.845a200.917 200.917 0 0 1 67.618 0v50.599c39.504 13.95 44.196 16.642 76.168 44.029l43.829-25.309a200.143 200.143 0 0 1 33.849 58.529l-43.819 25.289c7.631 41.3 7.634 46.718 0 88.037zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 144c-26.468 0-48-21.532-48-48 0-26.467 21.532-48 48-48s48 21.533 48 48c0 26.468-21.532 48-48 48z"]
  };
  var faDownload = {
    prefix: 'far',
    iconName: 'download',
    icon: [576, 512, [], "f019", "M528 288h-92.1l46.1-46.1c30.1-30.1 8.8-81.9-33.9-81.9h-64V48c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v112h-64c-42.6 0-64.2 51.7-33.9 81.9l46.1 46.1H48c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48zm-400-80h112V48h96v160h112L288 368 128 208zm400 256H48V336h140.1l65.9 65.9c18.8 18.8 49.1 18.7 67.9 0l65.9-65.9H528v128zm-88-64c0-13.3 10.7-24 24-24s24 10.7 24 24-10.7 24-24 24-24-10.7-24-24z"]
  };
  var faEllipsisH = {
    prefix: 'far',
    iconName: 'ellipsis-h',
    icon: [512, 512, [], "f141", "M304 256c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm120-48c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm-336 0c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"]
  };
  var faEllipsisV = {
    prefix: 'far',
    iconName: 'ellipsis-v',
    icon: [128, 512, [], "f142", "M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z"]
  };
  var faExclamation = {
    prefix: 'far',
    iconName: 'exclamation',
    icon: [256, 512, [], "f12a", "M173.854 48c6.874 0 12.343 5.763 11.984 12.628l-11.742 224c-.334 6.375-5.6 11.372-11.984 11.372H93.888c-6.383 0-11.65-4.997-11.984-11.372l-11.742-224C69.802 53.763 75.271 48 82.146 48h91.708M128 336c35.29 0 64 28.71 64 64s-28.71 64-64 64-64-28.71-64-64 28.71-64 64-64M173.854 0H82.146C47.881 0 20.427 28.783 22.228 63.141l11.742 224c.698 13.309 5.689 25.414 13.592 35.001C28.035 342.31 16 369.777 16 400c0 61.757 50.243 112 112 112s112-50.243 112-112c0-30.223-12.035-57.69-31.561-77.858a59.78 59.78 0 0 0 13.592-35.001l11.742-224C235.566 28.922 208.259 0 173.854 0z"]
  };
  var faExclamationCircle = {
    prefix: 'far',
    iconName: 'exclamation-circle',
    icon: [512, 512, [], "f06a", "M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z"]
  };
  var faExclamationTriangle = {
    prefix: 'far',
    iconName: 'exclamation-triangle',
    icon: [576, 512, [], "f071", "M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z"]
  };
  var faExternalLink = {
    prefix: 'far',
    iconName: 'external-link',
    icon: [512, 512, [], "f08e", "M497.6,0,334.4.17A14.4,14.4,0,0,0,320,14.57V47.88a14.4,14.4,0,0,0,14.69,14.4l73.63-2.72,2.06,2.06L131.52,340.49a12,12,0,0,0,0,17l23,23a12,12,0,0,0,17,0L450.38,101.62l2.06,2.06-2.72,73.63A14.4,14.4,0,0,0,464.12,192h33.31a14.4,14.4,0,0,0,14.4-14.4L512,14.4A14.4,14.4,0,0,0,497.6,0ZM432,288H416a16,16,0,0,0-16,16V458a6,6,0,0,1-6,6H54a6,6,0,0,1-6-6V118a6,6,0,0,1,6-6H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V304A16,16,0,0,0,432,288Z"]
  };
  var faEyeSlash = {
    prefix: 'far',
    iconName: 'eye-slash',
    icon: [640, 512, [], "f070", "M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"]
  };
  var faFileInvoiceDollar = {
    prefix: 'far',
    iconName: 'file-invoice-dollar',
    icon: [384, 512, [], "f571", "M369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95zM208 216c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v24.12c-23.62.63-42.67 20.55-42.67 45.07 0 19.97 12.98 37.81 31.58 43.39l45 13.5c5.16 1.55 8.77 6.78 8.77 12.73 0 7.27-5.3 13.19-11.8 13.19h-28.11c-4.56 0-8.96-1.29-12.82-3.72-3.24-2.03-7.36-1.91-10.13.73l-11.75 11.21c-3.53 3.37-3.33 9.21.57 12.14 9.1 6.83 20.08 10.77 31.37 11.35V424c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-24.12c23.62-.63 42.67-20.54 42.67-45.07 0-19.97-12.98-37.81-31.58-43.39l-45-13.5c-5.16-1.55-8.77-6.78-8.77-12.73 0-7.27 5.3-13.19 11.8-13.19h28.11c4.56 0 8.96 1.29 12.82 3.72 3.24 2.03 7.36 1.91 10.13-.73l11.75-11.21c3.53-3.37 3.33-9.21-.57-12.14-9.1-6.83-20.08-10.77-31.37-11.35V216zM88 112h80c4.42 0 8-3.58 8-8V88c0-4.42-3.58-8-8-8H88c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm88 56v-16c0-4.42-3.58-8-8-8H88c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h80c4.42 0 8-3.58 8-8z"]
  };
  var faFileSignature = {
    prefix: 'far',
    iconName: 'file-signature',
    icon: [576, 512, [], "f573", "M568.54 167.33l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01zM329.06 306a63.974 63.974 0 0 0-16.26 27.11L297.57 384h-24.76c-4.28 0-8.12-2.38-10.16-6.5-11.97-23.86-46.28-30.34-66-14.17l-13.88-41.62c-3.28-9.81-12.44-16.41-22.78-16.41s-19.5 6.59-22.78 16.41L119 376.36c-1.5 4.58-5.78 7.64-10.59 7.64H96c-8.84 0-16 7.16-16 16s7.16 16 16 16h12.41c18.62 0 35.09-11.88 40.97-29.53L160 354.58l16.81 50.48a15.994 15.994 0 0 0 14.06 10.89c.38.03.75.05 1.12.05 6.03 0 11.59-3.41 14.31-8.86l7.66-15.33c2.78-5.59 7.94-6.19 10.03-6.19s7.25.59 10.19 6.53c7.38 14.7 22.19 23.84 38.62 23.84H336V464H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v78l48-47.58v-74.5c0-12.7-5.17-25-14.17-33.99L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V388.8l134.66-135.58-67.88-67.88L329.06 306zM255.95 51.99l76.09 76.08h-76.09V51.99z"]
  };
  var faFileSpreadsheet = {
    prefix: 'far',
    iconName: 'file-spreadsheet',
    icon: [384, 512, [], "f65b", "M80 240v176c0 8.84 7.16 16 16 16h192c8.84 0 16-7.16 16-16V240c0-8.84-7.16-16-16-16H96c-8.84 0-16 7.16-16 16zm128 32h64v48h-64v-48zm0 80h64v48h-64v-48zm-96-80h64v48h-64v-48zm0 80h64v48h-64v-48zM369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95z"]
  };
  var faFragile = {
    prefix: 'far',
    iconName: 'fragile',
    icon: [288, 512, [], "f4bb", "M200 464h-32V349.4c72.7-12.4 126.3-79.5 119.4-156.7l-16-178.1C270.7 6.3 263.9 0 255.7 0H32.3c-8.2 0-15 6.3-15.7 14.6L.6 192.7C-6.3 269.9 47.3 337 120 349.4V464H88.1c-37.2 0-50 48-32 48h175.8c18.1 0 5.2-48-31.9-48zM72.6 272c-18.5-20.6-27.4-47.3-24.9-75L61.1 48h61.7l26.6 38L80 130l98.7 94-40.1-82L208 98l-24.8-50H227l13.3 149c2.5 27.8-6.4 54.4-24.9 75s-43.9 32-71.4 32-52.9-11.3-71.4-32z"]
  };
  var faGift = {
    prefix: 'far',
    iconName: 'gift',
    icon: [512, 512, [], "f06b", "M464 144h-26.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H48c-26.5 0-48 21.5-48 48v128c0 8.8 7.2 16 16 16h16v107.4c0 29 23.6 52.6 52.6 52.6h342.8c29 0 52.6-23.6 52.6-52.6V336h16c8.8 0 16-7.2 16-16V192c0-26.5-21.5-48-48-48zM232 448H84.6c-2.5 0-4.6-2-4.6-4.6V336h112v-48H48v-96h184v256zm-78.1-304c-22.1 0-40-17.9-40-40s17.9-40 40-40c22 0 37.5 7.6 84.1 77l2 3h-86.1zm122-3C322.5 71.6 338 64 360 64c22.1 0 40 17.9 40 40s-17.9 40-40 40h-86.1l2-3zM464 288H320v48h112v107.4c0 2.5-2 4.6-4.6 4.6H280V192h184v96z"]
  };
  var faGifts = {
    prefix: 'far',
    iconName: 'gifts',
    icon: [640, 512, [], "f79c", "M608 224h-20.4c2.6-7.6 4.4-15.5 4.4-23.8 0-35.5-27-72.2-72.1-72.2-48.1 0-75.9 47.7-87.9 75.3-11.4-26-36.9-69.4-80-74.3v-1c0-17.7-14.3-32-32-32h-61.4l30.7-22c7.2-5.1 8.9-15.1 3.7-22.3l-9.3-13c-5.1-7.2-15.1-8.9-22.3-3.7l-32 22.9 11.5-30.6c3.1-8.3-1.1-17.5-9.4-20.6l-15-5.6c-8.3-3.1-17.5 1.1-20.6 9.4l-19.9 53-19.9-53.1C153 2.1 143.8-2.1 135.5 1l-15 5.6c-8.3 3.1-12.5 12.3-9.4 20.6l11.5 30.6-32-22.8c-7.2-5.1-17.2-3.5-22.3 3.7l-9.3 13c-5.1 7.2-3.5 17.2 3.7 22.3l30.7 22H32c-17.7 0-32 14.3-32 32v352c0 17.7 14.3 32 32 32h576c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32zm-88.1-48c17.7 0 24.1 14.5 24.1 24.2 0 5.1-1.5 12.6-8.8 19-2.1 1.8-4.5 3.4-7.2 4.8h-52.6c8.8-20.3 25.9-48 44.5-48zm-175.8 0c18.7 0 35.6 27.4 44.5 48H336c-2.7-1.4-5.1-3-7.2-4.8-7.3-6.4-8.8-13.8-8.8-19 0-9.7 6.4-24.2 24.1-24.2zM224 256v208H48V144h250.7c-17 14-26.7 35.2-26.7 56.2 0 8.3 1.7 16.2 4.4 23.8H256c-17.7 0-32 14.3-32 32zm184 208H272v-72h136v72zm0-120H272v-72h136v72zm23.3-120l.7-.2.7.2h-1.4zM592 464H456v-72h136v72zm0-120H456v-72h136v72z"]
  };
  var faHome = {
    prefix: 'far',
    iconName: 'home',
    icon: [576, 512, [], "f015", "M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z"]
  };
  var faInfoCircle = {
    prefix: 'far',
    iconName: 'info-circle',
    icon: [512, 512, [], "f05a", "M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"]
  };
  var faInventory = {
    prefix: 'far',
    iconName: 'inventory',
    icon: [640, 512, [], "f480", "M624 0h-16c-8.8 0-16 7.2-16 16v144h-48V16c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16v144H48V16c0-8.8-7.2-16-16-16H16C7.2 0 0 7.2 0 16v496h48v-32h544v32h48V16c0-8.8-7.2-16-16-16zM368 48h128v112H368V48zM144 432V304h120v128H144zm168 0V304h120v128H312zm168 0V272c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16v160H48V208h544v224H480z"]
  };
  var faLayerGroup = {
    prefix: 'far',
    iconName: 'layer-group',
    icon: [512, 512, [], "f5fd", "M512 256.01c0-12.86-7.53-24.42-19.12-29.44l-79.69-34.58 79.66-34.57c11.62-5.03 19.16-16.59 19.16-29.44s-7.53-24.41-19.12-29.42L274.66 3.89c-11.84-5.17-25.44-5.19-37.28-.02L19.16 98.55C7.53 103.58 0 115.14 0 127.99s7.53 24.41 19.12 29.42l79.7 34.58-79.67 34.57C7.53 231.58 0 243.15 0 256.01c0 12.84 7.53 24.41 19.12 29.42L98.81 320l-79.65 34.56C7.53 359.59 0 371.15 0 384.01c0 12.84 7.53 24.41 19.12 29.42l218.28 94.69a46.488 46.488 0 0 0 18.59 3.88c6.34-.02 12.69-1.3 18.59-3.86l218.25-94.69c11.62-5.03 19.16-16.59 19.16-29.44 0-12.86-7.53-24.42-19.12-29.44L413.19 320l79.65-34.56c11.63-5.03 19.16-16.59 19.16-29.43zM255.47 47.89l.03.02h-.06l.03-.02zm.53.23l184.16 79.89-183.63 80.09-184.62-80.11L256 48.12zm184.19 335.92l-183.66 80.07L71.91 384l87.21-37.84 78.29 33.96A46.488 46.488 0 0 0 256 384c6.34-.02 12.69-1.3 18.59-3.86l78.29-33.97 87.31 37.87zM256.53 336.1L71.91 255.99l87.22-37.84 78.28 33.96a46.488 46.488 0 0 0 18.59 3.88c6.34-.02 12.69-1.3 18.59-3.86l78.29-33.97 87.31 37.88-183.66 80.06z"]
  };
  var faList = {
    prefix: 'far',
    iconName: 'list',
    icon: [512, 512, [], "f03a", "M80 48H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416-136H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16z"]
  };
  var faLongArrowLeft = {
    prefix: 'far',
    iconName: 'long-arrow-left',
    icon: [448, 512, [], "f177", "M152.485 396.284l19.626-19.626c4.753-4.753 4.675-12.484-.173-17.14L91.22 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H91.22l80.717-77.518c4.849-4.656 4.927-12.387.173-17.14l-19.626-19.626c-4.686-4.686-12.284-4.686-16.971 0L3.716 247.515c-4.686 4.686-4.686 12.284 0 16.971l131.799 131.799c4.686 4.685 12.284 4.685 16.97-.001z"]
  };
  var faMinus = {
    prefix: 'far',
    iconName: 'minus',
    icon: [384, 512, [], "f068", "M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"]
  };
  var faMusic = {
    prefix: 'far',
    iconName: 'music',
    icon: [512, 512, [], "f001", "M480.06 0a31.94 31.94 0 0 0-9.68 1.5l-304 96A32 32 0 0 0 144 128v235.09A109.68 109.68 0 0 0 96 352c-53 0-96 35.81-96 80s43 80 96 80c49.38 0 89.56-31.16 94.91-71.09a38.74 38.74 0 0 0 1.06-8.66V256l272-85.91v129A109.78 109.78 0 0 0 416 288c-53 0-96 35.81-96 80s43 80 96 80c49.38 0 89.56-31.19 94.94-71.12a38.94 38.94 0 0 0 1-8.22c0-.22.06-.44.06-.66V32a32 32 0 0 0-31.94-32zM96 464c-28.28 0-48-16.88-48-32s19.72-32 48-32 48 16.84 48 32-19.72 32-48 32zm368-96c0 15.12-19.72 32-48 32s-48-16.88-48-32 19.72-32 48-32 48 16.84 48 32zm0-248.25l-272 85.91v-65.91l272-85.87z"]
  };
  var faMusicSlash = {
    prefix: 'far',
    iconName: 'music-slash',
    icon: [640, 512, [], "f8d1", "M528 53.88v65.9l-189 59.68 45.86 35.85L528 170.1v157.15l48 37.52V32a31.95 31.95 0 0 0-41.62-30.5L233.05 96.66l45.87 35.86zM634 471L36 3.52A16 16 0 0 0 13.48 6l-10 12.49A16 16 0 0 0 6 41l598 467.5a16 16 0 0 0 22.5-2.5l10-12.5A16 16 0 0 0 634 471zM208 363.09A109.68 109.68 0 0 0 160 352c-53 0-96 35.81-96 80s43 80 96 80c49.37 0 89.56-31.16 94.91-71.09a38.69 38.69 0 0 0 1.06-8.63V297.37l-48-37.53zM160 464c-28.28 0-48-16.87-48-32s19.72-32 48-32 48 16.88 48 32-19.72 32-48 32z"]
  };
  var faPaperclip = {
    prefix: 'far',
    iconName: 'paperclip',
    icon: [512, 512, [], "f0c6", "M67.508 468.467c-58.005-58.013-58.016-151.92 0-209.943l225.011-225.04c44.643-44.645 117.279-44.645 161.92 0 44.743 44.749 44.753 117.186 0 161.944l-189.465 189.49c-31.41 31.413-82.518 31.412-113.926.001-31.479-31.482-31.49-82.453 0-113.944L311.51 110.491c4.687-4.687 12.286-4.687 16.972 0l16.967 16.971c4.685 4.686 4.685 12.283 0 16.969L184.983 304.917c-12.724 12.724-12.73 33.328 0 46.058 12.696 12.697 33.356 12.699 46.054-.001l189.465-189.489c25.987-25.989 25.994-68.06.001-94.056-25.931-25.934-68.119-25.932-94.049 0l-225.01 225.039c-39.249 39.252-39.258 102.795-.001 142.057 39.285 39.29 102.885 39.287 142.162-.028A739446.174 739446.174 0 0 1 439.497 238.49c4.686-4.687 12.282-4.684 16.969.004l16.967 16.971c4.685 4.686 4.689 12.279.004 16.965a755654.128 755654.128 0 0 0-195.881 195.996c-58.034 58.092-152.004 58.093-210.048.041z"]
  };
  var faPencil = {
    prefix: 'far',
    iconName: 'pencil',
    icon: [512, 512, [], "f040", "M491.609 73.625l-53.861-53.839c-26.378-26.379-69.076-26.383-95.46-.001L24.91 335.089.329 484.085c-2.675 16.215 11.368 30.261 27.587 27.587l148.995-24.582 315.326-317.378c26.33-26.331 26.581-68.879-.628-96.087zM120.644 302l170.259-169.155 88.251 88.251L210 391.355V350h-48v-48h-41.356zM82.132 458.132l-28.263-28.263 12.14-73.587L84.409 338H126v48h48v41.59l-18.282 18.401-73.586 12.141zm378.985-319.533l-.051.051-.051.051-48.03 48.344-88.03-88.03 48.344-48.03.05-.05.05-.05c9.147-9.146 23.978-9.259 33.236-.001l53.854 53.854c9.878 9.877 9.939 24.549.628 33.861z"]
  };
  var faPercentage = {
    prefix: 'far',
    iconName: 'percentage',
    icon: [320, 512, [], "f541", "M81.94 177.94c18.74-18.75 18.74-49.14 0-67.88-18.75-18.75-49.14-18.75-67.88 0-18.74 18.74-18.74 49.14 0 67.88 18.75 18.75 49.14 18.75 67.88 0zm156.12 156.12c-18.74 18.74-18.74 49.14 0 67.88 18.75 18.74 49.14 18.74 67.88 0 18.74-18.75 18.74-49.14 0-67.88-18.75-18.75-49.14-18.75-67.88 0zm77.25-210.75l-22.63-22.63c-6.25-6.25-16.38-6.25-22.63 0L4.69 366.06c-6.25 6.25-6.25 16.38 0 22.63l22.63 22.63c6.25 6.25 16.38 6.25 22.63 0l265.37-265.37c6.24-6.26 6.24-16.39-.01-22.64z"]
  };
  var faPlus = {
    prefix: 'far',
    iconName: 'plus',
    icon: [384, 512, [], "f067", "M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"]
  };
  var faPlusCircle = {
    prefix: 'far',
    iconName: 'plus-circle',
    icon: [512, 512, [], "f055", "M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z"]
  };
  var faPowerOff = {
    prefix: 'far',
    iconName: 'power-off',
    icon: [512, 512, [], "f011", "M388.5 46.3C457.9 90.3 504 167.8 504 256c0 136.8-110.8 247.7-247.5 248C120 504.3 8.2 393 8 256.4 7.9 168 54 90.3 123.5 46.3c5.8-3.7 13.5-1.8 16.9 4.2l11.8 20.9c3.1 5.5 1.4 12.5-3.9 15.9C92.8 122.9 56 185.1 56 256c0 110.5 89.5 200 200 200s200-89.5 200-200c0-70.9-36.8-133.1-92.3-168.6-5.3-3.4-7-10.4-3.9-15.9l11.8-20.9c3.3-6.1 11.1-7.9 16.9-4.3zM280 276V12c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v264c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12z"]
  };
  var faPrint = {
    prefix: 'far',
    iconName: 'print',
    icon: [512, 512, [], "f02f", "M400 264c-13.25 0-24 10.74-24 24 0 13.25 10.75 24 24 24s24-10.75 24-24c0-13.26-10.75-24-24-24zm32-88V99.88c0-12.73-5.06-24.94-14.06-33.94l-51.88-51.88c-9-9-21.21-14.06-33.94-14.06H110.48C93.64 0 80 14.33 80 32v144c-44.18 0-80 35.82-80 80v128c0 8.84 7.16 16 16 16h64v96c0 8.84 7.16 16 16 16h320c8.84 0 16-7.16 16-16v-96h64c8.84 0 16-7.16 16-16V256c0-44.18-35.82-80-80-80zM128 48h192v48c0 8.84 7.16 16 16 16h48v64H128V48zm256 416H128v-64h256v64zm80-112H48v-96c0-17.64 14.36-32 32-32h352c17.64 0 32 14.36 32 32v96z"]
  };
  var faQuestion = {
    prefix: 'far',
    iconName: 'question',
    icon: [384, 512, [], "f128", "M199.65 0C125.625 0 69.665 30.187 27.21 92.51c-19.17 28.15-12.94 66.3 14.17 86.86l36.73 27.85c10.81 8.2 24.19 12.79 37.74 12.96-11.84 19-17.82 40.61-17.82 64.55v11.43c0 16.38 6.2 31.34 16.38 42.65C97.99 357.2 88 381.45 88 408c0 57.35 46.65 104 104 104s104-46.65 104-104c0-26.55-9.99-50.8-26.41-69.19 8.66-9.62 14.43-21.87 15.97-35.38 28.287-16.853 96-48.895 96-138.21C381.56 71.151 290.539 0 199.65 0zM192 464c-30.88 0-56-25.12-56-56 0-30.873 25.118-56 56-56 30.887 0 56 25.132 56 56 0 30.88-25.12 56-56 56zm45.97-176.21v8.37c0 8.788-7.131 15.84-15.84 15.84h-60.26c-8.708 0-15.84-7.051-15.84-15.84v-11.43c0-47.18 35.77-66.04 62.81-81.2 23.18-13 37.39-21.83 37.39-39.04 0-22.77-29.04-37.88-52.52-37.88-30.61 0-44.74 14.49-64.6 39.56-5.365 6.771-15.157 8.01-22 2.8l-36.73-27.85c-6.74-5.11-8.25-14.6-3.49-21.59C98.08 73.73 137.8 48 199.65 48c64.77 0 133.91 50.56 133.91 117.22 0 88.51-95.59 89.87-95.59 122.57z"]
  };
  var faScanner = {
    prefix: 'far',
    iconName: 'scanner',
    icon: [640, 512, [], "f488", "M632 64H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm0-64H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 160H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 192H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-64H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM368 64H112C50.1 64 0 114.1 0 176c0 50.3 33.3 92.3 78.9 106.5L6.4 408C-6.9 431 1 460.3 24 473.6l55.4 32c7.6 4.4 15.8 6.4 24 6.4 16.6 0 32.7-8.6 41.6-24l60-104h67c26.5 0 48-21.5 48-48v-48h48c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM103.4 464L48 432l83.1-144H205L103.4 464zM288 336c0 8.8-7.2 16-16 16h-48.5l36.9-64H288v48zm48-96H112c-35.3 0-64-28.7-64-64s28.7-64 64-64h224v128zm296 208H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"]
  };
  var faSearch = {
    prefix: 'far',
    iconName: 'search',
    icon: [512, 512, [], "f002", "M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"]
  };
  var faSearchMinus = {
    prefix: 'far',
    iconName: 'search-minus',
    icon: [512, 512, [], "f010", "M312 196v24c0 6.6-5.4 12-12 12H116c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h184c6.6 0 12 5.4 12 12zm196.5 289.9l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L347.5 387.1c-2.3-2.3-3.5-5.3-3.5-8.5v-13.2c-36.5 31.5-84 50.6-136 50.6C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 52-19.1 99.5-50.6 136h13.2c3.2 0 6.2 1.3 8.5 3.5l121.4 121.4c4.7 4.7 4.7 12.3 0 17zM368 208c0-88.4-71.6-160-160-160S48 119.6 48 208s71.6 160 160 160 160-71.6 160-160z"]
  };
  var faSearchPlus = {
    prefix: 'far',
    iconName: 'search-plus',
    icon: [512, 512, [], "f00e", "M312 196v24c0 6.6-5.4 12-12 12h-68v68c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-68h-68c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h68v-68c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v68h68c6.6 0 12 5.4 12 12zm196.5 289.9l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L347.5 387.1c-2.3-2.3-3.5-5.3-3.5-8.5v-13.2c-36.5 31.5-84 50.6-136 50.6C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 52-19.1 99.5-50.6 136h13.2c3.2 0 6.2 1.3 8.5 3.5l121.4 121.4c4.7 4.7 4.7 12.3 0 17zM368 208c0-88.4-71.6-160-160-160S48 119.6 48 208s71.6 160 160 160 160-71.6 160-160z"]
  };
  var faShare = {
    prefix: 'far',
    iconName: 'share',
    icon: [576, 512, [], "f064", "M561.938 190.06L385.94 14.107C355.79-16.043 304 5.327 304 48.047v80.703C166.04 132.9 0 159.68 0 330.05c0 73.75 38.02 134.719 97.63 173.949 37.12 24.43 85.84-10.9 72.19-54.46C145.47 371.859 157.41 330.2 304 321.66v78.28c0 42.64 51.73 64.15 81.94 33.94l175.997-175.94c18.751-18.74 18.751-49.14.001-67.88zM352 400V272.09c-164.521 1.79-277.44 33.821-227.98 191.61C88 440 48 397.01 48 330.05c0-142.242 160.819-153.39 304-154.02V48l176 176-176 176z"]
  };
  var faShippingFast = {
    prefix: 'far',
    iconName: 'shipping-fast',
    icon: [640, 512, [], "f48b", "M624 368h-16V251.9c0-19-7.7-37.5-21.1-50.9L503 117.1C489.6 103.7 471 96 452.1 96H416V56c0-30.9-25.1-56-56-56H120C89.1 0 64 25.1 64 56v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H112V56c0-4.4 3.6-8 8-8h240c4.4 0 8 3.6 8 8v312H242.7c-16.6-28.6-47.2-48-82.7-48-17.6 0-33.8 5.1-48 13.3V288H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-464 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm256-320h36.1c6.3 0 12.5 2.6 17 7l73 73H416v-80zm64 320c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-100.9c-17.2-25.9-46.6-43.1-80-43.1-24.7 0-47 9.6-64 24.9V272h144v91.1zM256 248v-16c0-4.4-3.6-8-8-8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8zm24-56c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h240z"]
  };
  var faShippingTimed = {
    prefix: 'far',
    iconName: 'shipping-timed',
    icon: [640, 512, [], "f48c", "M208 88c-57.4 0-104 46.6-104 104s46.6 104 104 104 104-46.6 104-104S265.4 88 208 88zm48 128c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-80c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v56h24c4.4 0 8 3.6 8 8v16zm368 152h-16V251.9c0-19-7.7-37.5-21.1-50.9L503 117.1C489.6 103.7 471 96 452.1 96H416V56c0-30.9-25.1-56-56-56H56C25.1 0 0 25.1 0 56v304c0 30.9 25.1 56 56 56h8c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-464 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm208-96H242.7c-16.6-28.6-47.2-48-82.7-48s-66.1 19.4-82.7 48H56c-4.4 0-8-3.6-8-8V56c0-4.4 3.6-8 8-8h304c4.4 0 8 3.6 8 8v312zm48-224h36.1c6.3 0 12.5 2.6 17 7l73 73H416v-80zm64 320c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-100.9c-17.2-25.9-46.6-43.1-80-43.1-24.7 0-47 9.6-64 24.9V256h144v107.1z"]
  };
  var faShoppingBag = {
    prefix: 'far',
    iconName: 'shopping-bag',
    icon: [448, 512, [], "f290", "M352 128C352 57.42 294.579 0 224 0 153.42 0 96 57.42 96 128H0v304c0 44.183 35.817 80 80 80h288c44.183 0 80-35.817 80-80V128h-96zM224 48c44.112 0 80 35.888 80 80H144c0-44.112 35.888-80 80-80zm176 384c0 17.645-14.355 32-32 32H80c-17.645 0-32-14.355-32-32V176h48v40c0 13.255 10.745 24 24 24s24-10.745 24-24v-40h160v40c0 13.255 10.745 24 24 24s24-10.745 24-24v-40h48v256z"]
  };
  var faSignIn = {
    prefix: 'far',
    iconName: 'sign-in',
    icon: [512, 512, [], "f090", "M416 448h-84c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h84c26.5 0 48-21.5 48-48V160c0-26.5-21.5-48-48-48h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zM167.1 83.5l-19.6 19.6c-4.8 4.8-4.7 12.5.2 17.1L260.8 230H12c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h248.8L147.7 391.7c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l164.4-164c4.7-4.7 4.7-12.3 0-17l-164.4-164c-4.7-4.6-12.3-4.6-17 .1z"]
  };
  var faSpinner = {
    prefix: 'far',
    iconName: 'spinner',
    icon: [512, 512, [], "f110", "M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"]
  };
  var faSpinnerThird = {
    prefix: 'far',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"]
  };
  var faStarOfLife = {
    prefix: 'far',
    iconName: 'star-of-life',
    icon: [512, 512, [], "f621", "M288 512h-64c-22.06 0-40-17.94-40-40v-92.36l-90.93 46.83c-19.06 10.8-43.65 4.27-54.81-14.5L5.55 356.48c-5.5-9.31-6.97-20.14-4.16-30.53 2.81-10.44 9.59-19.11 19.06-24.44L101.04 256l-80.65-45.55c-9.41-5.3-16.19-13.97-19-24.41-2.81-10.39-1.34-21.22 4.12-30.5l32.75-55.47c11.16-18.81 35.72-25.31 54.81-14.53L184 132.35V40c0-22.06 17.94-40 40-40h64c22.06 0 40 17.94 40 40v92.36l90.93-46.83c19.09-10.78 43.65-4.28 54.81 14.5l32.72 55.48c5.5 9.31 6.97 20.14 4.16 30.53-2.81 10.44-9.59 19.11-19.06 24.44L410.96 256l80.59 45.52c9.47 5.33 16.25 14 19.06 24.44 2.81 10.39 1.34 21.22-4.12 30.5l-32.75 55.47c-11.12 18.81-35.68 25.34-54.81 14.53L328 379.65V472c-.01 22.06-17.94 40-40 40zm-56-48h48V297.42l156.21 83.67 24.65-41.78L305.34 256l155.52-83.31-24.65-41.78L280 214.58V48h-48v166.58L75.8 130.91l-24.65 41.78L206.66 256 51.14 339.31l24.65 41.78L232 297.42V464z"]
  };
  var faStopwatch = {
    prefix: 'far',
    iconName: 'stopwatch',
    icon: [448, 512, [], "f2f2", "M393.9 184l22.6-22.6c4.7-4.7 4.7-12.3 0-17l-17-17c-4.7-4.7-12.3-4.7-17 0l-20.7 20.7c-31.1-27.5-70.4-45.9-113.8-50.8V48h28c6.6 0 12-5.4 12-12V12c0-6.6-5.4-12-12-12H172c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h28v49.4C96.4 109.3 16 197.2 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-44.7-14.1-86.1-38.1-120zM224 464c-88.4 0-160-71.6-160-160s71.6-160 160-160 160 71.6 160 160-71.6 160-160 160zm12-112h-24c-6.6 0-12-5.4-12-12V204c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v136c0 6.6-5.4 12-12 12z"]
  };
  var faStoreAlt = {
    prefix: 'far',
    iconName: 'store-alt',
    icon: [640, 512, [], "f54f", "M635.7 176.1l-91.4-160C538.6 6.2 528 0 516.5 0h-393C112 0 101.4 6.2 95.7 16.1l-91.4 160C-7.9 197.5 7.4 224 32 224h32v252.8c0 19.4 14.3 35.2 32 35.2h256c17.7 0 32-15.8 32-35.2V224h144v272c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V224h32c24.6 0 39.9-26.5 27.7-47.9zM336 464H112v-95.8h224V464zm0-143.8H112V224h224v96.2zM59.6 176l73.1-128h374.5l73.1 128H59.6z"]
  };
  var faSyncAlt = {
    prefix: 'far',
    iconName: 'sync-alt',
    icon: [512, 512, [], "f2f1", "M483.515 28.485L431.35 80.65C386.475 35.767 324.485 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h28.045c6.353 0 11.613-4.952 11.973-11.294C66.161 141.649 151.453 60 256 60c54.163 0 103.157 21.923 138.614 57.386l-54.128 54.129c-7.56 7.56-2.206 20.485 8.485 20.485H492c6.627 0 12-5.373 12-12V36.971c0-10.691-12.926-16.045-20.485-8.486zM491.699 256h-28.045c-6.353 0-11.613 4.952-11.973 11.294C445.839 370.351 360.547 452 256 452c-54.163 0-103.157-21.923-138.614-57.386l54.128-54.129c7.56-7.56 2.206-20.485-8.485-20.485H20c-6.627 0-12 5.373-12 12v143.029c0 10.691 12.926 16.045 20.485 8.485L80.65 431.35C125.525 476.233 187.516 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z"]
  };
  var faTag = {
    prefix: 'far',
    iconName: 'tag',
    icon: [512, 512, [], "f02b", "M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a47.998 47.998 0 0 0 14.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM259.886 463.996L48 252.118V48h204.118L464 259.882 259.886 463.996zM192 144c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48z"]
  };
  var faTasksAlt = {
    prefix: 'far',
    iconName: 'tasks-alt',
    icon: [512, 512, [], "f828", "M488 31H24C10.7 31 0 43 0 58v74c0 15 10.7 27 24 27h464c13.3 0 24-12 24-27V58c0-15-10.7-27-24-27zm-184 80H48V79h256v32zm160 0H352V79h112v32zm24 240H24c-13.3 0-24 12-24 27v74c0 15 10.7 27 24 27h464c13.3 0 24-12 24-27v-74c0-15-10.7-27-24-27zm-248 80H48v-32h192v32zm224 0H288v-32h176v32zm24-240H24c-13.3 0-24 12-24 27v74c0 15 10.7 27 24 27h464c13.3 0 24-12 24-27v-74c0-15-10.7-27-24-27zm-376 80H48v-32h64v32zm352 0H160v-32h304v32z"]
  };
  var faTimes = {
    prefix: 'far',
    iconName: 'times',
    icon: [320, 512, [], "f00d", "M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"]
  };
  var faTreePalm = {
    prefix: 'far',
    iconName: 'tree-palm',
    icon: [640, 512, [], "f82b", "M448.76 64c-39.43 0-75.06 11.74-103 30.5C327.14 40.17 265.37 0 191.24 0c-80.61 0-147.37 47.24-159 108.86C30.39 118.79 38.75 128 50 128h46l32-48 33.46 66.92c-3.53 3.07-7.28 5.69-10.66 9.07C93.8 213 80 293.6 115.37 345.38c5.7 8.34 18.12 8.94 26.07 1l124.92-124.92c2.17 99.11-20.8 196.29-32.92 240-6.91 25.18 11.66 50.54 38.19 50.54h90.43a39.63 39.63 0 0 0 39.28-33.48c16.94-104.88-1.7-218-17.46-286.52H448l32-48 32 48h78c11.25 0 19.61-9.21 17.74-19.14C596.13 111.24 529.38 64 448.76 64zM355 464h-72.53c14.42-54.48 37.74-163.81 30.32-272h21.68c14.81 61.61 35.01 171.89 20.53 272z"]
  };
  var faTruck = {
    prefix: 'far',
    iconName: 'truck',
    icon: [640, 512, [], "f0d1", "M624 368h-16V251.9c0-19-7.7-37.5-21.1-50.9L503 117.1C489.6 103.7 471 96 452.1 96H416V56c0-30.9-25.1-56-56-56H56C25.1 0 0 25.1 0 56v304c0 30.9 25.1 56 56 56h8c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-464 96c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm208-96H242.7c-16.6-28.6-47.2-48-82.7-48s-66.1 19.4-82.7 48H56c-4.4 0-8-3.6-8-8V56c0-4.4 3.6-8 8-8h304c4.4 0 8 3.6 8 8v312zm48-224h36.1c6.3 0 12.5 2.6 17 7l73 73H416v-80zm64 320c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-100.9c-17.2-25.9-46.6-43.1-80-43.1-24.7 0-47 9.6-64 24.9V272h144v91.1z"]
  };
  var faUfo = {
    prefix: 'far',
    iconName: 'ufo',
    icon: [640, 512, [], "e047", "M128,296a24,24,0,1,0,24,24A23.99993,23.99993,0,0,0,128,296Zm192,40a24,24,0,1,0,24,24A23.99993,23.99993,0,0,0,320,336ZM483.47656,210.10938C474.207,128.15039,404.87891,64,320,64c-84.87695,0-154.20312,64.15039-163.47461,146.10742C62.91016,232.44141,0,273.23047,0,320c0,70.69141,143.26953,128,320,128,176.73242,0,320-57.30859,320-128C640,273.23047,577.09375,232.44336,483.47656,210.10938ZM320,112c64.80273,0,117.334,52.0957,117.334,116.36328,0,5.22461-.877,10.21289-1.55078,15.25586a250.36631,250.36631,0,0,1-231.5664,0c-.67188-5.043-1.54883-10.03125-1.54883-15.25586C202.668,164.0957,255.19727,112,320,112Zm0,288c-178.47656,0-272-59.44141-272-80,0-13.05078,37.81445-41.72656,110.98438-60.96289a47.81277,47.81277,0,0,0,23.043,27.14648,298.37591,298.37591,0,0,0,275.94532,0,47.80892,47.80892,0,0,0,23.04492-27.14648C554.18555,278.27344,592,306.94922,592,320,592,340.55859,498.47852,400,320,400ZM512,296a24,24,0,1,0,24,24A23.99993,23.99993,0,0,0,512,296Z"]
  };
  var faUsers = {
    prefix: 'far',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zm396.4 210.9c-27.5-40.8-80.7-56-127.8-41.7-14.2 4.3-29.1 6.7-44.7 6.7s-30.5-2.4-44.7-6.7c-47.1-14.3-100.3.8-127.8 41.7-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c.2-23.8-7-45.9-19.4-64.3zM464 432H176v-44.8c0-36.4 29.2-66.2 65.4-67.2 25.5 10.6 51.9 16 78.6 16 26.7 0 53.1-5.4 78.6-16 36.2 1 65.4 30.7 65.4 67.2V432zm92-176h-24c-17.3 0-33.4 5.3-46.8 14.3 13.4 10.1 25.2 22.2 34.4 36.2 3.9-1.4 8-2.5 12.3-2.5h24c19.8 0 36 16.2 36 36 0 13.2 10.8 24 24 24s24-10.8 24-24c.1-46.3-37.6-84-83.9-84zm-236 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-176c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM154.8 270.3c-13.4-9-29.5-14.3-46.8-14.3H84c-46.3 0-84 37.7-84 84 0 13.2 10.8 24 24 24s24-10.8 24-24c0-19.8 16.2-36 36-36h24c4.4 0 8.5 1.1 12.3 2.5 9.3-14 21.1-26.1 34.5-36.2z"]
  };

  exports.faAbacus = faAbacus;
  exports.faAlarmClock = faAlarmClock;
  exports.faAnalytics = faAnalytics;
  exports.faAngleDown = faAngleDown;
  exports.faAngleUp = faAngleUp;
  exports.faArchive = faArchive;
  exports.faArrowLeft = faArrowLeft;
  exports.faArrowRight = faArrowRight;
  exports.faArrowsH = faArrowsH;
  exports.faArrowsV = faArrowsV;
  exports.faBars = faBars;
  exports.faBolt = faBolt;
  exports.faBoxCheck = faBoxCheck;
  exports.faBoxFull = faBoxFull;
  exports.faBoxUp = faBoxUp;
  exports.faBoxes = faBoxes;
  exports.faCalculator = faCalculator;
  exports.faCaretUp = faCaretUp;
  exports.faChartLine = faChartLine;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronLeft = faChevronLeft;
  exports.faChevronRight = faChevronRight;
  exports.faChevronUp = faChevronUp;
  exports.faCircle = faCircle;
  exports.faCog = faCog;
  exports.faDownload = faDownload;
  exports.faEllipsisH = faEllipsisH;
  exports.faEllipsisV = faEllipsisV;
  exports.faExclamation = faExclamation;
  exports.faExclamationCircle = faExclamationCircle;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faExternalLink = faExternalLink;
  exports.faEyeSlash = faEyeSlash;
  exports.faFileInvoiceDollar = faFileInvoiceDollar;
  exports.faFileSignature = faFileSignature;
  exports.faFileSpreadsheet = faFileSpreadsheet;
  exports.faFragile = faFragile;
  exports.faGift = faGift;
  exports.faGifts = faGifts;
  exports.faHome = faHome;
  exports.faInfoCircle = faInfoCircle;
  exports.faInventory = faInventory;
  exports.faLayerGroup = faLayerGroup;
  exports.faList = faList;
  exports.faLongArrowLeft = faLongArrowLeft;
  exports.faMinus = faMinus;
  exports.faMusic = faMusic;
  exports.faMusicSlash = faMusicSlash;
  exports.faPaperclip = faPaperclip;
  exports.faPencil = faPencil;
  exports.faPercentage = faPercentage;
  exports.faPlus = faPlus;
  exports.faPlusCircle = faPlusCircle;
  exports.faPowerOff = faPowerOff;
  exports.faPrint = faPrint;
  exports.faQuestion = faQuestion;
  exports.faScanner = faScanner;
  exports.faSearch = faSearch;
  exports.faSearchMinus = faSearchMinus;
  exports.faSearchPlus = faSearchPlus;
  exports.faShare = faShare;
  exports.faShippingFast = faShippingFast;
  exports.faShippingTimed = faShippingTimed;
  exports.faShoppingBag = faShoppingBag;
  exports.faSignIn = faSignIn;
  exports.faSpinner = faSpinner;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faStarOfLife = faStarOfLife;
  exports.faStopwatch = faStopwatch;
  exports.faStoreAlt = faStoreAlt;
  exports.faSyncAlt = faSyncAlt;
  exports.faTag = faTag;
  exports.faTasksAlt = faTasksAlt;
  exports.faTimes = faTimes;
  exports.faTreePalm = faTreePalm;
  exports.faTruck = faTruck;
  exports.faUfo = faUfo;
  exports.faUsers = faUsers;

  Object.defineProperty(exports, '__esModule', { value: true });

});
