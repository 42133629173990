define("ember-google-maps/components/g-map/rectangle", ["exports", "ember-google-maps/components/g-map/typical-map-component"], function (_exports, _typicalMapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Rectangle extends _typicalMapComponent.default {
    get name() {
      return 'rectangles';
    }

    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.Rectangle(options);
    }

  }

  _exports.default = Rectangle;
});