define("ember-google-maps/components/g-map/waypoint", ["exports", "ember-google-maps/components/g-map/map-component"], function (_exports, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function WaypointAPI(source) {
    let {
      options
    } = source;
    return {
      get location() {
        return options.location;
      },

      get stopover() {
        return options.stopover;
      }

    };
  }

  class Waypoint extends _mapComponent.default {
    get publicAPI() {
      return WaypointAPI(this);
    }

    register() {
      this.onTeardown = this.args.getContext(this.publicAPI);
    }

    setup() {
      return this.publicAPI;
    } // Removes the waypoint from the directions component.


    teardown() {
      this.onTeardown();
    }

  }

  _exports.default = Waypoint;
});