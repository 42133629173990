define("@streethub/mirage-api/factories/curate-brand", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    status: 'published',
    is_retail_price_incl_vat: true,
    is_wholesale_price_incl_vat: false,

    name() {
      return _faker.default.company.companyName();
    },

    slug() {
      return Ember.String.dasherize(this.name);
    },

    description() {
      return _faker.default.lorem.paragraph();
    },

    discount_message() {
      return _faker.default.lorem.sentence();
    },

    payment_terms() {
      return _faker.default.lorem.sentence();
    },

    delivery_info() {
      return _faker.default.lorem.sentence();
    },

    minimum_order_value() {
      return {
        description: _faker.default.lorem.sentence()
      };
    },

    logo() {
      return {
        url: _faker.default.image.avatar()
      };
    },

    images() {
      return Array(6).fill().map(() => ({
        url: _faker.default.image.fashion()
      }));
    },

    catalogues() {
      return [{
        url: '',
        filename: 'this-is-a-very-long-brand-name-catalogue_price-list-2020'
      }, {
        url: '',
        filename: 'Brand-name_price-list-2021'
      }];
    },

    unpublished: (0, _emberCliMirage.trait)({
      status: 'unpublished'
    }),
    preview: (0, _emberCliMirage.trait)({
      status: 'preview'
    }),

    afterCreate(item) {
      item.update({
        _id: item.id
      });
    }

  });

  _exports.default = _default;
});