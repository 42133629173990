define("@streethub/mirage-api/resources/curate-category", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('categories', function (schema, request) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, request.queryParams);
      Object.assign(queryParams, {
        parent: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.parent) === 'null' ? undefined : queryParams.parent
      });
      return schema.curateCategories.where(queryParams);
    });
    server.get('categories/:id', function (schema, request) {
      const {
        id
      } = request.params;
      const curateCategory = schema.curateCategories.find(id);

      if (!curateCategory) {
        return schema.curateCategories.none();
      }

      return curateCategory;
    });
    server.post('categories', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.curateCategories.create(requestBody);
    });
    server.put('categories/:id', function (schema, request) {
      const id = request.params.id;
      const curateCategory = schema.curateCategories.find(id);
      const body = JSON.parse(request.requestBody);
      return curateCategory.update(body);
    });
    server.get('/categories-hierarchy', function (schema, request) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, request.queryParams);
      Object.assign(queryParams, {
        _id: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.id) === 'null' ? undefined : queryParams.id,
        brand: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.brand) === 'null' ? undefined : queryParams.brand
      });
      return {
        categories_hierarchy: schema.curateCategories.where(queryParams).models
      };
    });
  }
});