define("in-repo-pin-addon/components/g-map-addons/pin", ["exports", "ember-google-maps/components/g-map/map-component"], function (_exports, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GMap::Marker @lat="51.507568" @lng="-0.127762" @getContext={{@getContext}} />
  */
  {
    "id": "M6qlYwq5",
    "block": "{\"symbols\":[\"@getContext\"],\"statements\":[[8,\"g-map/marker\",[],[[\"@lat\",\"@lng\",\"@getContext\"],[\"51.507568\",\"-0.127762\",[32,1]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "in-repo-pin-addon/components/g-map-addons/pin.hbs"
  });

  class Pin extends _mapComponent.default {
    get name() {
      return 'pin';
    }

    setup() {}

  }

  _exports.default = Pin;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Pin);
});