define("@streethub/mirage-api/resources/curate-brand", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('brands', function (schema, request) {
      const {
        queryParams: {
          status,
          ...restQueryParams
        }
      } = request;
      const queryParams = (0, _request.normalizeQueryParams)(schema, restQueryParams);
      let brands = schema.curateBrands.where(queryParams);
      const queryParamStatus = (Array.isArray(status) ? status : [status]).filter(Boolean);

      if (queryParamStatus.length) {
        brands = brands.filter(_ref => {
          let {
            status
          } = _ref;
          return queryParamStatus.includes(status);
        });
      }

      return brands;
    });
    server.get('brands/:id', function (schema, request) {
      const id = request.params.id;
      const curateBrand = schema.curateBrands.find(id);

      if (!curateBrand) {
        return schema.curateBrands.none();
      }

      return curateBrand;
    });
    server.post('brands', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.curateBrands.create(requestBody);
    });
    server.put('brands/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.curateBrands.update(id, body);
    });
  }
});