define('ember-metrics/metrics-adapters/google-tag-manager', ['exports', 'ember-metrics/utils/can-use-dom', 'ember-metrics/utils/object-transforms', 'ember-metrics/utils/remove-from-dom', 'ember-metrics/metrics-adapters/base'], function (exports, _canUseDom, _objectTransforms, _removeFromDom, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const compact = _objectTransforms.default.compact;
  exports.default = _base.default.extend({
    dataLayer: 'dataLayer',

    toStringExtension() {
      return 'GoogleTagManager';
    },

    init() {
      const config = Ember.get(this, 'config');
      const id = config.id,
            envParams = config.envParams;

      const dataLayer = Ember.getWithDefault(config, 'dataLayer', 'dataLayer');
      const envParamsString = envParams ? `&${envParams}` : '';

      (false && !(id) && Ember.assert(`[ember-metrics] You must pass a valid \`id\` to the ${this.toString()} adapter`, id));


      Ember.set(this, 'dataLayer', dataLayer);

      if (_canUseDom.default) {
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + envParamsString;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', Ember.get(this, 'dataLayer'), id);
      }
    },

    trackEvent(options = {}) {
      const compactedOptions = compact(options);
      const dataLayer = Ember.get(this, 'dataLayer');
      const gtmEvent = { 'event': compactedOptions['event'] };

      delete compactedOptions['event'];

      for (let key in compactedOptions) {
        const capitalizedKey = Ember.String.capitalize(key);
        gtmEvent[`event${capitalizedKey}`] = compactedOptions[key];
      }

      if (_canUseDom.default) {
        window[dataLayer].push(gtmEvent);
      }

      return gtmEvent;
    },

    trackPage(options = {}) {
      const compactedOptions = compact(options);
      const dataLayer = Ember.get(this, 'dataLayer');
      const sendEvent = {
        event: compactedOptions['event'] || 'pageview'
      };

      const pageEvent = Ember.assign(sendEvent, compactedOptions);

      if (_canUseDom.default) {
        window[dataLayer].push(pageEvent);
      }

      return pageEvent;
    },

    willDestroy() {
      if (!_canUseDom.default) {
        return;
      }
      (0, _removeFromDom.default)('script[src*="gtm.js"]');

      delete window.dataLayer;
    }
  });
});