define("@streethub/mirage-api/resources/index", ["exports", "@streethub/mirage-api/resources/curate-brand-tag", "@streethub/mirage-api/resources/boutique-restock-information", "@streethub/mirage-api/resources/currency", "@streethub/mirage-api/resources/stock-line", "@streethub/mirage-api/resources/country", "@streethub/mirage-api/resources/packaging-order", "@streethub/mirage-api/resources/packaging-promotion-code", "@streethub/mirage-api/resources/packaging-product", "@streethub/mirage-api/resources/packaging-category", "@streethub/mirage-api/resources/curate-order", "@streethub/mirage-api/resources/curate-brand", "@streethub/mirage-api/resources/curate-product", "@streethub/mirage-api/resources/curate-category", "@streethub/mirage-api/resources/boutique", "@streethub/mirage-api/resources/user", "@streethub/mirage-api/resources/brand", "@streethub/mirage-api/resources/product", "@streethub/mirage-api/resources/auth", "@streethub/mirage-api/resources/zone"], function (_exports, _curateBrandTag, _boutiqueRestockInformation, _currency, _stockLine, _country, _packagingOrder, _packagingPromotionCode, _packagingProduct, _packagingCategory, _curateOrder, _curateBrand, _curateProduct, _curateCategory, _boutique, _user, _brand, _product, _auth, _zone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "auth", {
    enumerable: true,
    get: function () {
      return _auth.default;
    }
  });
  Object.defineProperty(_exports, "boutique", {
    enumerable: true,
    get: function () {
      return _boutique.default;
    }
  });
  Object.defineProperty(_exports, "boutiqueRestockInformation", {
    enumerable: true,
    get: function () {
      return _boutiqueRestockInformation.default;
    }
  });
  Object.defineProperty(_exports, "brand", {
    enumerable: true,
    get: function () {
      return _brand.default;
    }
  });
  Object.defineProperty(_exports, "country", {
    enumerable: true,
    get: function () {
      return _country.default;
    }
  });
  Object.defineProperty(_exports, "curateBrand", {
    enumerable: true,
    get: function () {
      return _curateBrand.default;
    }
  });
  Object.defineProperty(_exports, "curateBrandTag", {
    enumerable: true,
    get: function () {
      return _curateBrandTag.default;
    }
  });
  Object.defineProperty(_exports, "curateCategory", {
    enumerable: true,
    get: function () {
      return _curateCategory.default;
    }
  });
  Object.defineProperty(_exports, "curateOrder", {
    enumerable: true,
    get: function () {
      return _curateOrder.default;
    }
  });
  Object.defineProperty(_exports, "curateProduct", {
    enumerable: true,
    get: function () {
      return _curateProduct.default;
    }
  });
  Object.defineProperty(_exports, "currency", {
    enumerable: true,
    get: function () {
      return _currency.default;
    }
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "packagingCategory", {
    enumerable: true,
    get: function () {
      return _packagingCategory.default;
    }
  });
  Object.defineProperty(_exports, "packagingOrder", {
    enumerable: true,
    get: function () {
      return _packagingOrder.default;
    }
  });
  Object.defineProperty(_exports, "packagingProduct", {
    enumerable: true,
    get: function () {
      return _packagingProduct.default;
    }
  });
  Object.defineProperty(_exports, "packagingPromotionCode", {
    enumerable: true,
    get: function () {
      return _packagingPromotionCode.default;
    }
  });
  Object.defineProperty(_exports, "product", {
    enumerable: true,
    get: function () {
      return _product.default;
    }
  });
  Object.defineProperty(_exports, "stockLine", {
    enumerable: true,
    get: function () {
      return _stockLine.default;
    }
  });
  Object.defineProperty(_exports, "user", {
    enumerable: true,
    get: function () {
      return _user.default;
    }
  });
  Object.defineProperty(_exports, "zone", {
    enumerable: true,
    get: function () {
      return _zone.default;
    }
  });
  var _default = {
    auth: _auth.default,
    product: _product.default,
    brand: _brand.default,
    user: _user.default,
    boutique: _boutique.default,
    curateProduct: _curateProduct.default,
    curateBrand: _curateBrand.default,
    curateOrder: _curateOrder.default,
    curateCategory: _curateCategory.default,
    packagingProduct: _packagingProduct.default,
    packagingCategory: _packagingCategory.default,
    packagingPromotionCode: _packagingPromotionCode.default,
    packagingOrder: _packagingOrder.default,
    country: _country.default,
    stockLine: _stockLine.default,
    currency: _currency.default,
    boutiqueRestockInformation: _boutiqueRestockInformation.default,
    curateBrandTag: _curateBrandTag.default,
    zone: _zone.default
  };
  _exports.default = _default;
});