define("ember-intl/-private/utils/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  /**
   * @private
   * @hide
   */
  function missingMessage(key, locales, _options) {
    if (Ember.isEmpty(locales)) {
      (false && Ember.warn(`[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl`, false, {
        id: 'ember-intl-no-locale-set'
      }));
      return `No locale defined.  Unable to resolve translation: "${key}"`;
    }

    const localeNames = locales.join(', ');
    (false && Ember.warn(`[ember-intl] translation: "${key}" on locale: "${localeNames}" was not found.`, false, {
      id: 'ember-intl-missing-translation'
    }));
    return `Missing translation "${key}" for locale "${localeNames}"`;
  }
});