define("ember-svg-jar/inlined/insights-product-listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M117.516 44.152c0-.237-.237-.474-.473-.474h-3.471v-3.47a.509.509 0 00-.474-.474h-1.262a.48.48 0 00-.473.473v3.471h-3.472a.48.48 0 00-.473.474v1.262c0 .276.197.473.473.473h3.472v3.472c0 .276.197.473.473.473h1.262a.48.48 0 00.474-.473v-3.472h3.471a.48.48 0 00.473-.473v-1.262zm4.734.63A9.781 9.781 0 00112.467 35a9.781 9.781 0 00-9.783 9.783 9.781 9.781 0 009.783 9.782 9.781 9.781 0 009.783-9.782zm-1.894 0c0 4.38-3.55 7.89-7.889 7.89a7.862 7.862 0 01-7.889-7.89c0-4.338 3.511-7.888 7.889-7.888 4.339 0 7.889 3.55 7.889 7.889zM74 14a8 8 0 018-8h11v78H82a8 8 0 01-8-8V14z\" fill=\"#242038\"/><path d=\"M76 15a7 7 0 017-7h8v74h-8a7 7 0 01-7-7V15z\" fill=\"#fff\"/><path d=\"M3 14a8 8 0 018-8h46a8 8 0 018 8v62a8 8 0 01-8 8H11a8 8 0 01-8-8V14z\" fill=\"#242038\"/><path d=\"M5 15a7 7 0 017-7h44a7 7 0 017 7v60a7 7 0 01-7 7H12a7 7 0 01-7-7V15z\" fill=\"#fff\"/><path d=\"M51.407 21.676c.42.241.719.604.898 1.087.18.483.12.966-.12 1.388l-3.053 6.34c-.24.423-.6.725-1.078.906-.42.181-.958.121-1.377-.12l-2.874-1.45v14.371a2.695 2.695 0 01-2.695 2.717H27.276c-1.437 0-2.635-1.207-2.635-2.717v-14.37l-2.934 1.448a1.573 1.573 0 01-1.377.121 1.888 1.888 0 01-1.078-.966l-3.053-6.28c-.24-.422-.24-.905-.12-1.388a2.02 2.02 0 01.898-1.087L28.234 16l.48.724c.897 1.329 3.053 2.174 5.508 2.174 2.395 0 4.55-.845 5.449-2.174L40.15 16l11.257 5.676z\" fill=\"#242038\"/><rect x=\"9\" y=\"56.567\" width=\"50.129\" height=\"3.198\" rx=\"1.599\" fill=\"#C8C7CD\"/><rect x=\"9\" y=\"64.562\" width=\"50.129\" height=\"3.198\" rx=\"1.599\" fill=\"#C8C7CD\"/><rect x=\"9\" y=\"72.558\" width=\"23.745\" height=\"3.198\" rx=\"1.599\" fill=\"#C8C7CD\"/>",
    "attrs": {
      "width": "123",
      "height": "87",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});