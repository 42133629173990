define("ember-google-maps/components/g-map/info-window", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/utils/helpers"], function (_exports, _mapComponent, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.container}}
    {{#in-element this.container}}
      {{#if (has-block)}}
        {{yield this.publicAPI}}
      {{else}}
        {{this.content}}
      {{/if}}
    {{/in-element}}
  {{/if}}
  */
  {
    "id": "Z80aU/89",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"container\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"container\"]]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[32,0,[\"publicAPI\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,0,[\"content\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"in-element\"]}",
    "moduleName": "ember-google-maps/components/g-map/info-window.hbs"
  });

  let InfoWindow = (_dec = Ember._tracked, (_class = class InfoWindow extends _mapComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "container", _descriptor, this);
    }

    get name() {
      return 'infoWindows';
    }

    get isOpen() {
      return Boolean(this.infoWindow.getMap());
    } // TODO: Sanitize this.args.content?


    get content() {
      var _this$args$content;

      return (_this$args$content = this.args.content) !== null && _this$args$content !== void 0 ? _this$args$content : this.container;
    } // Can’t use a fragment here because Google Maps consumes it.


    get newOptions() {
      let options = this.options;

      if (!options.target && !this.args.position) {
        options.position = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }

      if (options.isOpen) {
        options.content = this.content;
      }

      return options;
    }

    setup() {
      let infoWindow = new google.maps.InfoWindow(this.newOptions); // This is kind of annoying. Maybe we can refactor stuff to not use `this`.

      this.infoWindow = infoWindow;
      this.addEventsToMapComponent(infoWindow, this.events, this.publicAPI);
      this.toggleOpen();
      return infoWindow;
    }

    update(infoWindow) {
      infoWindow.setOptions(this.newOptions);
      this.toggleOpen();
    }

    toggleOpen() {
      var _this$args$isOpen;

      let shouldBeOpen = (_this$args$isOpen = this.args.isOpen) !== null && _this$args$isOpen !== void 0 ? _this$args$isOpen : false;

      if (shouldBeOpen === this.isOpen) {
        return;
      }

      if (shouldBeOpen) {
        this.open();
      } else {
        this.close();
      }
    }

    open() {
      this.infoWindow.open(this.map, this.options.target);
    }

    close() {
      this.infoWindow.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "container", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      var _window, _window$document;

      return (_window = window) === null || _window === void 0 ? void 0 : (_window$document = _window.document) === null || _window$document === void 0 ? void 0 : _window$document.createElement('div');
    }
  })), _class));
  _exports.default = InfoWindow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, InfoWindow);
});