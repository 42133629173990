define("@streethub/mirage-api/resources/packaging-order", ["exports", "@streethub/mirage-api/utils/request", "faker"], function (_exports, _request, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('orders', function (schema, request) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, request.queryParams);
      return schema.packagingOrders.where(queryParams);
    });
    server.get('orders/:id', function (schema, request) {
      const id = request.params.id;
      const packagingOrder = schema.packagingOrders.find(id);

      if (!packagingOrder) {
        return schema.packagingOrders.none();
      }

      return packagingOrder;
    });
    server.post('orders', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.packagingOrders.create(requestBody);
    });
    server.post('orders/payment-intent', () => {
      return new Response(200, {
        payment_intent: {
          client_secret: _faker.default.datatype.uuid()
        }
      });
    });
  }
});