define("@streethub/mirage-api/resources/product", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    context.optionalData = null;

    function findResources(schema, query) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, query);
      return schema.products.where(queryParams);
    }

    server.get('products', function (schema, request) {
      return findResources(schema, request.queryParams);
    });
    server.get('products/:id', function (schema, request) {
      const id = request.params.id;
      const product = schema.products.find(id);

      if (!product) {
        return schema.products.none();
      }

      return product;
    });
    server.post('products', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);

      if ((0, _request.hasGetMethodOverrideHeader)(request)) {
        return findResources(schema, requestBody);
      }

      return schema.products.create(requestBody);
    });
    server.put('products/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.products.update(id, body);
    });
    server.del('products/:id', function (schema, request) {
      const id = request.params.id;
      const product = schema.products.find(id);
      return product.destroy();
    });
  }
});