define("ember-svg-jar/inlined/packaging-user-headset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.5 27.5h-.39c-.938 0-1.876.234-2.813.547-1.172.39-3.125.781-4.375.781-1.172 0-3.125-.39-4.297-.781a8.848 8.848 0 00-2.813-.469H10.5c-5.547 0-10 4.453-10 9.922C.5 38.906 1.594 40 3 40h30c1.328 0 2.5-1.094 2.5-2.5 0-5.469-4.531-10-10-10zM3 37.5c0-4.063 3.36-7.422 7.5-7.5h.313c.546 0 1.171.156 1.953.469 1.406.468 3.75.781 5.156.781 1.484 0 3.828-.313 5.234-.781.782-.313 1.407-.469 1.953-.469h.391c4.063.078 7.422 3.438 7.5 7.5H3zm1.25-20c.625 0 1.25-.547 1.25-1.25V15C5.5 8.125 11.047 2.5 18 2.5c6.875 0 12.5 5.625 12.5 12.5v1.25c-.078 3.516-2.813 6.25-6.25 6.25h-2.5c0-1.328-1.172-2.5-2.5-2.5h-2.5c-1.406 0-2.5 1.172-2.5 2.5 0 1.406 1.094 2.5 2.5 2.5h7.5c4.766 0 8.672-3.906 8.75-8.75V15c0-8.203-6.797-15-15-15C9.719 0 3 6.797 3 15v1.25c0 .703.547 1.25 1.25 1.25zM18 7.5c4.14 0 7.422 3.36 7.422 7.5 0 1.563-.86 3.828-1.875 5h.703c1.094 0 2.5-.781 3.125-1.719A9.08 9.08 0 0028 15c0-5.469-4.531-10-10-10C12.453 5 8 9.531 8 15c0 3.203 1.484 5.938 3.75 7.813V22.5c0-.703.313-1.797.625-2.5-1.016-1.172-1.953-3.36-1.953-5 0-4.14 3.36-7.5 7.5-7.5H18z\" fill=\"#4EA089\"/>",
    "attrs": {
      "width": "36",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});