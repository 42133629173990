define("ember-svg-jar/inlined/curate-brand-referral-modal-shape-05", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".5\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.078 17.357c-1.09 2.131-2.82 4.179-5.042 4.96-2.187.77-4.344-.195-6.368-.947-1.816-.675-3.422-1.662-4.633-3.14C1.78 16.695.905 14.893.678 12.837.422 10.53-.065 7.7 1.67 5.925c1.767-1.807 4.685-.31 6.977-1.24C10.932 3.76 12.416.38 14.742.626c2.27.24 2.978 3.1 3.926 5.118.792 1.686.981 3.538 1.05 5.472.073 2.076.355 4.198-.64 6.142z\" fill=\"#F0E2DF\"/>",
    "attrs": {
      "width": "20",
      "height": "23",
      "viewBox": "0 0 20 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});