define("@streethub/mirage-api/resources/auth", ["exports", "ember-cli-mirage", "@streethub/mirage-api/scenarios/_test-users"], function (_exports, _emberCliMirage, _testUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Attach data to be shared across resources
    context.loggedIn = true;
    context.user = null; // Setup users for login endpoint

    (0, _testUsers.default)(server); // Routes

    server.get('isloggedin', function () {
      return {
        isLoggedIn: context.loggedIn,
        user: context.user
      };
    });
    server.post('login', function (schema, request) {
      const data = JSON.parse(request.requestBody);
      const loggedInUser = schema.db.users.findBy({
        email: data.email,
        password: data.password
      });
      const isLoggedIn = Boolean(loggedInUser);
      context.loggedIn = isLoggedIn;
      context.user = loggedInUser;

      if (context.loggedIn) {
        return { ...context.user,
          isLoggedIn: context.loggedIn
        };
      }

      return new _emberCliMirage.Response(403, {}, {
        errors: [{
          code: 'AuthError',
          detail: 'Credentials incorrect',
          status: 403,
          title: 'Not authorized'
        }]
      });
    });
    server.get('logout', function () {
      context.loggedIn = false;
      context.user = null;
    });
  }
});