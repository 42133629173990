define("ember-svg-jar/inlined/packaging-box-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M47.594 9.25l-3.828-8.047C43.53.813 43.14.5 42.672.5L24 3S5.328.5 5.25.5c-.469 0-.86.313-1.094.703L.328 9.25c-.312.703 0 1.563.781 1.797l3.829 1.172v16.484c0 1.172.78 2.188 1.796 2.422l16.094 4.297c1.016.234 1.797.078 2.266 0l16.093-4.297c1.016-.234 1.797-1.25 1.797-2.422V12.22l3.828-1.172c.782-.235 1.094-1.094.782-1.797zM5.954 3.156l14.062 1.875-4.453 7.813-12.5-3.75 2.89-5.938zm1.405 9.766c9.141 2.812 8.516 2.578 8.75 2.578.47 0 .86-.156 1.016-.547L22.75 5.11v27.657L7.36 28.703V12.922zm33.203 15.781l-15.39 4.063V5.109l5.625 9.766c.234.39.625.625 1.015.625.235 0-.39.156 8.75-2.578v15.781zm-8.203-15.86l-4.453-7.812 14.14-1.875 2.813 5.938-12.5 3.75z\" fill=\"#4EA089\"/>",
    "attrs": {
      "width": "48",
      "height": "36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});