define("@streethub/mirage-api/factories/packaging-order", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getPricing = () => ({
    subtotal_price: {
      amount: _faker.default.datatype.number({
        min: 999,
        max: 499900
      }),
      currency: 'GBP'
    },
    vat_price: {
      amount: _faker.default.datatype.number({
        min: 999,
        max: 499900
      }),
      currency: 'GBP'
    },
    delivery_price: {
      amount: 795,
      currency: 'GBP'
    },

    total_price() {
      return {
        amount: this.pricing.subtotal_price.amount + this.pricing.vat_price.amount + this.pricing.delivery_price.amount,
        currency: 'GBP'
      };
    }

  });

  const getReservation = () => ({
    quantity: _faker.default.datatype.number({
      min: 1,
      max: 20
    }),
    product: {
      id: _faker.default.datatype.uuid(),
      name: _faker.default.commerce.productName(),
      image: {
        url: _faker.default.image.fashion()
      },
      variant: {
        sku: `${_faker.default.random.word()}-${_faker.default.datatype.number({
          min: 100,
          max: 999
        })}`,
        dimensions: {
          length: {
            value: _faker.default.datatype.number({
              min: 10,
              max: 100
            }),
            unit: 'cm'
          },
          height: {
            value: _faker.default.datatype.number({
              min: 10,
              max: 100
            }),
            unit: 'cm'
          },
          width: {
            value: _faker.default.datatype.number({
              min: 10,
              max: 100
            }),
            unit: 'cm'
          }
        },
        color: _faker.default.commerce.color(),
        pack_size: _faker.default.random.arrayElement([5, 10, 15, 25, 50]),
        pack_price: {
          amount: _faker.default.datatype.number({
            min: 99,
            max: 5999
          }),
          currency: 'GBP'
        }
      }
    }
  });

  var _default = _emberCliMirage.Factory.extend({
    created_by: '',
    pricing: getPricing(),

    id() {
      return _faker.default.datatype.uuid();
    },

    _id() {
      return this.id;
    },

    order_number(i) {
      return i;
    },

    delivery_details() {
      return {
        name: _faker.default.name.findName(),
        email: _faker.default.internet.email(),
        phone_number: _faker.default.phone.phoneNumber(),
        address: {
          line_1: _faker.default.address.streetAddress(),
          line_2: '',
          city: _faker.default.address.city(),
          postcode: _faker.default.address.zipCode(),
          country: _faker.default.address.countryCode()
        }
      };
    },

    billing_details() {
      return {
        name: _faker.default.name.findName(),
        email: _faker.default.internet.email(),
        phone_number: _faker.default.phone.phoneNumber(),
        vat_number: '',
        address: {
          line_1: _faker.default.address.streetAddress(),
          line_2: ''
        }
      };
    },

    boutique_details() {
      return {
        id: _faker.default.datatype.uuid(),
        name: _faker.default.company.companyName(),
        registered_name: _faker.default.company.companyName(),
        country: _faker.default.address.countryCode()
      };
    },

    created_at() {
      return _faker.default.date.past();
    },

    updated_at() {
      return this.created_at;
    },

    updated_by() {
      return this.created_by;
    },

    reservations() {
      return [getReservation(), getReservation(), getReservation()];
    },

    discount: (0, _emberCliMirage.trait)({
      pricing: {
        promotion_code: 'THIS-IS-A-CODE',
        discount_price: {
          amount: 795,
          currency: 'GBP'
        },
        ...getPricing()
      }
    })
  });

  _exports.default = _default;
});