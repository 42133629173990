define("@streethub/mirage-api/factories/country", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    shipping_enabled: false,

    name() {
      return _faker.default.address.country();
    },

    weengs_name() {
      return this.name;
    },

    iso_code() {
      return _faker.default.address.countryCode();
    },

    iso_code_alpha_3() {
      return `${this.iso_code}X`;
    },

    created_at() {
      return _faker.default.date.past();
    },

    isShippingEnabled: (0, _emberCliMirage.trait)({
      shipping_enabled: true
    }),
    UK: (0, _emberCliMirage.trait)({
      name: 'The United Kingdom of Great Britain and Northern Ireland',
      weengs_name: 'United Kingdom',
      iso_code: 'GB',
      iso_code_alpha_3: 'GBR'
    }),

    afterCreate(item) {
      // Set "_id" to be the same as mirage's "id"
      item.update({
        _id: item.id
      });
    }

  });

  _exports.default = _default;
});