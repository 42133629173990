define("@streethub/mirage-api/resources/packaging-category", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('categories', function (schema, request) {
      const {
        metadata,
        variants_country,
        ...restQueryParams
      } = request.queryParams;
      const queryParams = (0, _request.normalizeQueryParams)(schema, restQueryParams);
      return schema.packagingCategories.where(queryParams);
    });
    server.get('categories/:id', function (schema, request) {
      const id = request.params.id;
      const packagingCategory = schema.packagingCategories.find(id);

      if (!packagingCategory) {
        return schema.packagingCategories.none();
      }

      return packagingCategory;
    });
    server.post('categories', function (schema, request) {
      const requestBody = JSON.parse(request.requestBody);
      return schema.packagingCategories.create(requestBody);
    });
    server.put('categories/:id', function (schema, request) {
      const id = request.params.id;
      const body = JSON.parse(request.requestBody);
      return schema.packagingCategories.update(id, body);
    });
  }
});