define('@streethub/ember-trouva-elements/components/trouva-elements/empty-template', ['exports', '@streethub/ember-trouva-elements/templates/components/trouva-elements/empty-template'], function (exports, _emptyTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emptyTemplate.default,
    tagName: ''
  });
});