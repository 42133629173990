define("ember-svg-jar/inlined/packaging-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M43.156.781C42.922.312 42.453 0 41.984 0c-.39 0-.78.234-1.093.703C38.469 4.922 34.17 7.5 29.25 7.5H23c-8.281 0-15 6.719-15 15 0 1.64.234 3.203.703 4.688C2.766 32.266.578 38.28.5 38.358c-.156.625.156 1.407.781 1.641.625.156 1.407-.156 1.64-.781 0-.078 1.876-5.078 6.798-9.61C12.297 34.297 17.219 37.5 23 37.5c.469 0 1.016 0 1.563-.078C36.828 36.562 45.5 25.547 45.5 12.109c0-3.906-.86-7.968-2.344-11.328zM24.328 35H23c-5 0-9.219-2.89-11.25-7.031 4.063-3.047 9.688-5.469 17.5-5.469.625 0 1.25-.547 1.25-1.25 0-.625-.625-1.25-1.25-1.25-8.047 0-13.984 2.422-18.36 5.547-.234-1.016-.39-1.953-.39-3.047C10.5 15.625 16.047 10 23 10h6.25c4.922 0 9.453-2.188 12.422-6.016A26.036 26.036 0 0143 12.11C43 24.531 35.11 34.141 24.328 35z\" fill=\"#4EA089\"/>",
    "attrs": {
      "width": "46",
      "height": "41",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});