define("@streethub/mirage-api/factories/currency", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    iso_4217: 'GBP',
    symbol: '£',
    name: 'British Pounds',

    afterCreate(item) {
      // Set "_id" to be the same as mirage's "id"
      item.update({
        _id: item.id
      });
    }

  });

  _exports.default = _default;
});