define('@streethub/ember-trouva-elements/default-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = defaultTo;
  function defaultTo(value) {
    return Ember.computed({
      get() {
        return value;
      },
      set(_, newVal) {
        return newVal === undefined ? value : newVal;
      }
    });
  }
});