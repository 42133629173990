define("@streethub/mirage-api/serializers/application", ["exports", "ember-cli-mirage", "ember-inflector", "@streethub/mirage-api/utils/request", "@streethub/mirage-api/utils/records"], function (_exports, _emberCliMirage, _emberInflector, _request, _records) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function keyForModelOrCollection(modelName) {
    let key = Ember.String.underscore((0, _emberInflector.pluralize)(modelName));
    ['packaging_', 'curate_'].forEach(prefix => key = key.replace(prefix, ''));
    return key;
  }

  var _default = _emberCliMirage.RestSerializer.extend({
    include(request) {
      const queryParams = (0, _request.hasGetMethodOverrideHeader)(request) ? JSON.parse(request.requestBody) : request.queryParams; // Promenade uses "populate" instead of include to populate records

      if (queryParams.populate) {
        return queryParams.populate.split(',');
      } // Promenade uses "attach_related" instead of include to attach related records


      if (queryParams.attach_related) {
        return [Ember.String.camelize(queryParams.attach_related)];
      }

      return [];
    },

    embed: true,
    serializeIds: 'always',
    keyForForeignKey: relationshipName => relationshipName,
    keyForPolymorphicForeignKeyId: relationshipName => relationshipName,
    keyForEmbeddedRelationship: type => Ember.String.underscore(type),
    keyForRelationshipIds: type => Ember.String.underscore(type),
    keyForRelationship: type => type,
    keyForAttribute: attr => attr,
    keyForModel: modelName => keyForModelOrCollection(modelName),
    keyForCollection: modelName => keyForModelOrCollection(modelName),

    // ember-cli-mirage doesn't inject the foreign keys when embed: true so injecting
    // manually here, using the library's own method for extracting associations
    injectForeignKeys(model, record) {
      return { ...this._maybeAddAssociationIds(model),
        ...record
      };
    },

    serialize(resource, request) {
      let json = _emberCliMirage.RestSerializer.prototype.serialize.apply(this, arguments);

      const rootResourceKey = this.keyForResource(resource); // Promenade always returns an array of one item if we're requesting a single item
      // from a resource, so we need to cast the response to an array

      if (this.isModel(resource)) {
        return {
          [rootResourceKey]: [this.injectForeignKeys(resource, json[rootResourceKey])]
        };
      }

      let records = resource.models.map((model, index) => {
        const serializedRecord = json[rootResourceKey][index];
        return this.injectForeignKeys(model, serializedRecord);
      });
      let meta = this.getMeta(records, request.queryParams.page, request.queryParams.limit); // The client can paginate and sort the records

      records = this.sortRecords(request.queryParams, records);
      records = this.paginateRecords(request.queryParams, records);
      return {
        [rootResourceKey]: records,
        meta
      };
    },

    getMeta(records, page, limit) {
      return {
        page: parseInt(page || 1, 10),
        page_total: records.length === 0 || !limit ? 1 : Math.ceil(records.length / limit),
        total: records.length
      };
    },

    sortRecords(queryParams, records) {
      if (typeof queryParams.sort === 'string') {
        return (0, _records.sortRecords)(queryParams.sort, records);
      }

      return records;
    },

    paginateRecords(queryParams, records) {
      const {
        limit,
        page
      } = (0, _request.paginationParams)(queryParams);

      if (typeof limit === 'number' && typeof page === 'number') {
        return (0, _records.paginateRecords)(page, limit, records);
      }

      return records;
    }

  });

  _exports.default = _default;
});