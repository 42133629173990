define("ember-svg-jar/inlined/packaging-shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M34.585 25.364a38.824 38.824 0 00-4.83-2.739c.223-4.096.782-19.813-3.912-17.445-4.43 2.234 1.026 13.23 3.11 17.074a38.817 38.817 0 00-4.845-1.795C21.09 18.466 7.232 9.737 2.777 14.356c-4.663 4.835 14.116 7.478 20.67 6.72a38.274 38.274 0 015.814 2.503c-4.09 1.097-18.39 5.322-16.84 10.397 2.076 6.797 14.703-7.026 17.405-10.09a38.34 38.34 0 014.019 2.566c.526.4 1.043.81 1.551 1.231-3.962 1.939-15.952 8.18-14.15 11.899 2.06 4.247 13.063-.75 15.16-11.038a46.528 46.528 0 015.818 6.133 64.792 64.792 0 012.42 3.354c-3.593 1.255-15.47 5.806-13.712 10.544 1.78 4.8 11.412-6.741 14.05-10.036a64.853 64.853 0 017.925 16.856l2.95-.826a67.464 67.464 0 00-5.151-11.144 64.847 64.847 0 00-2.008-3.278c.988-4.549 3.119-15.751-.33-13.817-2.768 1.55-2.02 7.57-1.13 11.68a64.807 64.807 0 00-3.566-4.56c-.14-.161-.28-.322-.423-.481 3.161-6.363 2.692-21.054-1.54-20.929-3.66.108-.482 14.954.71 20.021a47.24 47.24 0 00-5.346-4.88c-.046-4.134-.435-22.73-3.683-19.575-5.54 5.43-.23 14.739 2.403 18.609-.398-.29-.8-.573-1.208-.85z\" fill=\"#A7CFC4\"/><path d=\"M44.613 17.524h33.806c2.9 0 5.25 2.35 5.25 5.25V57.96H44.613a5.25 5.25 0 01-5.25-5.25V22.774c0-2.9 2.35-5.25 5.25-5.25z\" fill=\"#fff\" stroke=\"#242038\" stroke-width=\"1.5\"/><path d=\"M89.752 29.032h-5.978v14.839h19.418c.858 0 1.318-1.009.755-1.656l-9.668-11.12a6 6 0 00-4.527-2.063z\" fill=\"#fff\" stroke=\"#242038\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M83.879 42.685h18.476a3.25 3.25 0 013.25 3.25V57.71a.25.25 0 01-.25.25H83.879V42.685z\" fill=\"#fff\" stroke=\"#242038\" stroke-width=\"1.5\"/><path d=\"M61.734 61.936a7.637 7.637 0 11-15.275 0 7.637 7.637 0 0115.275 0z\" fill=\"#242038\" stroke=\"#242038\" stroke-width=\"1.5\"/><circle cx=\"96.032\" cy=\"61.936\" r=\"7.637\" fill=\"#242038\" stroke=\"#242038\" stroke-width=\"1.5\"/>",
    "attrs": {
      "width": "127",
      "height": "83",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});