define("@streethub/mirage-api/factories/curate-category", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    status: 'published',

    name() {
      return _faker.default.commerce.productAdjective();
    },

    _id() {
      return this.id;
    },

    unpublished: (0, _emberCliMirage.trait)({
      status: 'unpublished'
    })
  });

  _exports.default = _default;
});