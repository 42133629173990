define("ember-svg-jar/inlined/curate-empty-order-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 72.343a82.388 82.388 0 0143.801 25.935 82.504 82.504 0 0120.225 46.739 82.485 82.485 0 0120.297-46.52A82.371 82.371 0 01128 72.69a82.388 82.388 0 01-43.795-25.946A82.503 82.503 0 0163.99 0a82.52 82.52 0 01-20.304 46.53A82.405 82.405 0 010 72.343z\" fill=\"#FFA425\"/><path d=\"M0 72.343a82.388 82.388 0 0143.801 25.935 82.504 82.504 0 0120.225 46.739 82.485 82.485 0 0120.297-46.52A82.371 82.371 0 01128 72.69a82.388 82.388 0 01-43.795-25.946A82.503 82.503 0 0163.99 0a82.52 82.52 0 01-20.304 46.53A82.405 82.405 0 010 72.343z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "128",
      "height": "145",
      "fill": "none"
    }
  };
  _exports.default = _default;
});