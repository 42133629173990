define("ember-svg-jar/inlined/curate-monogram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#1f102d\" d=\"M101.5 84.1c0-3.2-1.2-5.9-3.5-8.3-2.3-2.4-5.1-3.5-8.3-3.5-3.2 0-6 1.2-8.3 3.5-2.3 2.3-3.5 5.1-3.5 8.3 0 3.3 1.2 6 3.5 8.3 2.3 2.2 5.1 3.3 8.3 3.3 3.3 0 6.1-1.1 8.4-3.4s3.4-5 3.4-8.2z\"/><path fill=\"#1f102d\" d=\"M27.2 84c33.9-7.5 59.3-37.8 59.3-74-40.7 0-73.8 32-75.7 72.2h-.1v3.6h.1c1.9 40.2 35 72.2 75.7 72.2 0-36.2-25.4-66.5-59.3-74zm141.4-1.8C166.6 42 133.5 10 92.8 10c0 36.2 25.4 66.5 59.3 74-33.9 7.5-59.3 37.8-59.3 74 40.7 0 73.8-32 75.7-72.2h.1v-3.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 179.3 168"
    }
  };
  _exports.default = _default;
});