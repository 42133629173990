define("@streethub/mirage-api/factories/packaging-category", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return _faker.default.random.word();
    },

    slug() {
      return Ember.String.dasherize(this.name);
    },

    afterCreate(category) {
      // Set "_id" to be the same as mirage's "id"
      category.update({
        _id: category.id
      });
    }

  });

  _exports.default = _default;
});