define("ember-svg-jar/inlined/handsfree-bulk-upload-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"100\" height=\"70.259\" x=\"6\" fill=\"#BBA9CD\" opacity=\".2\" rx=\"35.129\"/><path fill=\"#242038\" stroke=\"#242038\" d=\"M87.297 54.303l-.018-.023-.02-.02-5.411-5.405a.005.005 0 00-.001-.001 1.152 1.152 0 00-.82-.354c-.31 0-.598.132-.82.355l-5.357 5.407.341.338-.341-.338c-.43.434-.5 1.194-.003 1.641l.246.298.014.018.016.016c.438.44 1.213.51 1.655-.016l2.902-2.886v7.074c0 .658.493 1.162 1.156 1.162h.438c.58 0 1.156-.477 1.156-1.162v-7.061l2.862 2.889c.221.223.51.355.82.355s.6-.132.82-.355l.328-.331c.225-.228.309-.533.309-.8 0-.272-.085-.564-.272-.8z\"/><rect width=\"56.36\" height=\"42.61\" x=\"1.5\" y=\"19.518\" fill=\"#fff\" stroke=\"#242038\" stroke-width=\"3\" rx=\"7.5\"/><path fill=\"#C8C7CD\" d=\"M10.334 49.147v5.092h37.891V42.703l-6.772-6.785a4 4 0 00-5.707.046l-11.14 11.52-4.175-4.318a3 3 0 00-4.313 0l-5.784 5.98z\"/><ellipse cx=\"15.501\" cy=\"33.037\" fill=\"#C8C7CD\" rx=\"5.167\" ry=\"5.301\"/><rect width=\"56.36\" height=\"42.61\" x=\"16.141\" y=\"4.5\" fill=\"#fff\" stroke=\"#242038\" stroke-width=\"3\" rx=\"7.5\"/><path fill=\"#C8C7CD\" d=\"M25.834 34.127v5.093h37.891V27.683l-7.491-7.504a3 3 0 00-4.28.034L40.106 32.463l-4.175-4.317a3 3 0 00-4.313 0l-5.784 5.98z\"/><ellipse cx=\"31.001\" cy=\"18.017\" fill=\"#C8C7CD\" rx=\"5.167\" ry=\"5.301\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 106 71"
    }
  };
  _exports.default = _default;
});