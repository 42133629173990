define("@streethub/mirage-api/factories/curate-order", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    first_order: false,

    order_number(i) {
      return i + 1;
    },

    contact_details() {
      return {
        contact_name: _faker.default.name.findName(),
        email_address: _faker.default.internet.email(),
        phone_number: _faker.default.phone.phoneNumber()
      };
    },

    invoice_details() {
      return {
        address: {
          line_1: _faker.default.address.streetAddress(),
          line_2: `${_faker.default.address.zipCode()} ${_faker.default.address.city()}`
        }
      };
    },

    delivery_details() {
      return {
        line_1: _faker.default.address.streetAddress(),
        line_2: `${_faker.default.address.zipCode()} ${_faker.default.address.city()}`
      };
    },

    created_at() {
      return _faker.default.date.recent();
    },

    created_by() {
      return _faker.default.datatype.uuid();
    },

    updated_at() {
      return this.created_at;
    },

    updated_by() {
      return this.created_by;
    },

    firstOrder: (0, _emberCliMirage.trait)({
      first_order: true
    }),

    afterCreate(order, server) {
      if (order.brands.length || order.reservations.length) {
        order.update({
          _id: order.id
        });
        return;
      }

      const currency = _faker.default.random.arrayElement(['GBP', 'EUR']);

      const brand = server.create('curate-brand');
      const products = server.createList('curate-product', 8, {
        brand,
        currency,
        quantity: () => _faker.default.datatype.number({
          min: 1,
          max: 20
        })
      }).map(_ref => {
        let {
          attrs
        } = _ref;
        return attrs;
      });
      const totalPriceAmount = products.reduce((total, _ref2) => {
        let {
          wholesale_price: {
            amount
          },
          quantity
        } = _ref2;
        return total + amount * quantity;
      }, 0);
      order.update({
        _id: order.id,
        reservations: products.map(product => {
          const {
            stock_lines: [stock_line],
            quantity,
            brand,
            _id,
            ...rest
          } = product;
          return {
            stock_line,
            product: rest,
            _id,
            brand,
            quantity
          };
        }),
        brands: [brand.attrs],
        skus: products.map(_ref3 => {
          let {
            sku
          } = _ref3;
          return sku;
        }),
        total_price: {
          amount: totalPriceAmount,
          currency
        }
      });
    }

  });

  _exports.default = _default;
});