define("@streethub/mirage-api/factories/product", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    brand: (0, _emberCliMirage.association)(),
    archived: false,
    published: true,

    name() {
      return _faker.default.commerce.productName();
    },

    thumbnail() {
      return {
        url: _faker.default.image.abstract()
      };
    },

    created_at() {
      return _faker.default.date.past();
    },

    unpublished: (0, _emberCliMirage.trait)({
      published: false
    }),
    isArchived: (0, _emberCliMirage.trait)({
      archived: true
    }),

    afterCreate(product, server) {
      var _product$stockLines;

      const boutique = product.boutique || server.create('boutique');
      const update = {
        _id: product.id,
        boutiques: [{
          boutique: boutique.attrs.id
        }]
      };

      if (!((_product$stockLines = product.stockLines) !== null && _product$stockLines !== void 0 && _product$stockLines.length)) {
        const stockLines = server.createList('stock-line', 4, {
          boutique: boutique.attrs.id
        });
        const [{
          local_price: localPrice
        }] = stockLines;
        Object.assign(update, {
          stockLines,
          local_price: localPrice
        });
      }

      product.update(update);
    }

  });

  _exports.default = _default;
});