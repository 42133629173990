define("flaneur/mirage/models/curate-order", ["exports", "@streethub/mirage-api/models/curate-order"], function (_exports, _curateOrder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _curateOrder.default;
    }
  });
});