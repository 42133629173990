define("ember-gesture-modifiers/modifiers/did-pan", ["exports", "ember-modifier", "ember-gesture-modifiers/utils/parse-touch-data"], function (_exports, _emberModifier, _parseTouchData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const _fn = () => {};

  let DidPanModifier = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class DidPanModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "threshold", void 0);

      _defineProperty(this, "axis", void 0);

      _defineProperty(this, "capture", void 0);

      _defineProperty(this, "preventScroll", void 0);

      _defineProperty(this, "pointerTypes", void 0);

      _defineProperty(this, "currentTouches", new Map());

      _defineProperty(this, "dragging", false);
    }

    addEventListeners() {
      var _this$pointerTypes;

      // By default, CSS rule `touch-action` is `auto`, enabling panning on both directions.
      // We override panning on a given direction, so we need to disable default browser behavior
      // on that diretion, but we need to keep the other direction pannable.
      // Thus, we set `touch-action` to `pan-y` when we pan horizontally and vice versa.
      if (this.axis === 'horizontal') {
        this.element.style.touchAction = 'pan-y';
      } else if (this.axis === 'vertical') {
        this.element.style.touchAction = 'pan-x';
      } else if (this.axis === 'both') {
        this.element.style.touchAction = 'none';
      }

      this.element.addEventListener('pointerdown', this.didTouchStart, {
        capture: this.capture,
        passive: true
      });
      this.element.addEventListener('pointermove', this.didTouchMove, {
        capture: this.capture,
        passive: !this.preventScroll
      });
      this.element.addEventListener('pointerup', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });
      this.element.addEventListener('pointercancel', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });

      if ((_this$pointerTypes = this.pointerTypes) !== null && _this$pointerTypes !== void 0 && _this$pointerTypes.includes('mouse')) {
        document.addEventListener('pointermove', this.documentPointerMove, {
          capture: this.capture,
          passive: !this.preventScroll
        });
        document.addEventListener('pointercancel', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
        document.addEventListener('pointerup', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
      }
    }

    removeEventListeners() {
      var _this$pointerTypes2;

      this.element.style.touchAction = null;
      this.element.removeEventListener('pointerdown', this.didTouchStart, {
        capture: this.capture,
        passive: true
      });
      this.element.removeEventListener('pointermove', this.didTouchMove, {
        capture: this.capture,
        passive: !this.preventScroll
      });
      this.element.removeEventListener('pointerup', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });
      this.element.removeEventListener('pointercancel', this.didTouchEnd, {
        capture: this.capture,
        passive: true
      });

      if ((_this$pointerTypes2 = this.pointerTypes) !== null && _this$pointerTypes2 !== void 0 && _this$pointerTypes2.includes('mouse')) {
        document.removeEventListener('pointermove', this.documentPointerMove, {
          capture: this.capture,
          passive: !this.preventScroll
        });
        document.removeEventListener('pointercancel', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
        document.removeEventListener('pointerup', this.documentPointerUp, {
          capture: this.capture,
          passive: true
        });
      }
    }

    didTouchStart(e) {
      if (!this.dragging && this.pointerTypes.includes(e.pointerType)) {
        const touchData = (0, _parseTouchData.parseInitialTouchData)(e);
        this.currentTouches.set(e.pointerId, touchData);
        this.dragging = true;
      }
    }

    didTouchMove(e) {
      if (e.pointerType !== 'mouse') {
        this.handleTouchMove(e);
      }
    }

    didTouchEnd(e) {
      if (e.pointerType !== 'mouse') {
        this.handleTouchEnd(e);
      }
    }

    documentPointerMove(e) {
      if (this.dragging && e.pointerType === 'mouse' && this.pointerTypes.includes(e.pointerType)) {
        this.handleTouchMove(e);
      }
    }

    documentPointerUp(e) {
      if (this.dragging && e.pointerType === 'mouse' && this.pointerTypes.includes(e.pointerType)) {
        this.handleTouchEnd(e);
      }
    }

    handleTouchMove(e) {
      if (this.dragging && this.currentTouches.has(e.pointerId)) {
        const previousTouchData = this.currentTouches.get(e.pointerId);
        const touchData = (0, _parseTouchData.parseTouchData)(previousTouchData, e);

        if (touchData.panStarted) {
          // prevent scroll if a pan is still busy
          if (this.preventScroll) {
            e.preventDefault();
          }

          this.didPan(touchData.data);
        } else {
          console.log(touchData); // only pan when the threshold for the given axis is achieved

          if (!touchData.panDenied && (this.axis === 'horizontal' && Math.abs(touchData.data.current.distanceX) > this.threshold || this.axis === 'vertical' && Math.abs(touchData.data.current.distanceY) > this.threshold || this.axis === 'both' && Math.abs(touchData.data.current.distance) > this.threshold)) {
            // test if axis matches with data else deny the pan
            if (this.axis === 'horizontal' && (0, _parseTouchData.isHorizontal)(touchData) || this.axis === 'vertical' && (0, _parseTouchData.isVertical)(touchData) || this.axis === 'both') {
              // prevent scroll if a pan is detected
              if (this.preventScroll) {
                e.preventDefault();
              }

              touchData.panStarted = true; // trigger panStart hook

              this.didPanStart(touchData.data);
            } else {
              touchData.panDenied = true;
            }
          }
        }

        this.currentTouches.set(e.pointerId, touchData);
      }
    }

    handleTouchEnd(e) {
      if (this.dragging && this.currentTouches.has(e.pointerId)) {
        this.dragging = false;
        const previousTouchData = this.currentTouches.get(e.pointerId);
        const touchData = (0, _parseTouchData.parseTouchData)(previousTouchData, e);

        if (touchData.panStarted) {
          this.didPanEnd(touchData.data);
        }

        this.currentTouches.delete(e.pointerId);
      }
    }

    didReceiveArguments() {
      var _this$args$named$thre, _this$args$named$axis, _this$args$named$capt, _this$args$named$prev, _this$args$named$poin, _this$args$named$onPa, _this$args$named$onPa2, _this$args$named$onPa3;

      this.removeEventListeners();
      this.threshold = (_this$args$named$thre = this.args.named.threshold) !== null && _this$args$named$thre !== void 0 ? _this$args$named$thre : 10;
      this.axis = (_this$args$named$axis = this.args.named.axis) !== null && _this$args$named$axis !== void 0 ? _this$args$named$axis : 'horizontal';
      this.capture = (_this$args$named$capt = this.args.named.capture) !== null && _this$args$named$capt !== void 0 ? _this$args$named$capt : false;
      this.preventScroll = (_this$args$named$prev = this.args.named.preventScroll) !== null && _this$args$named$prev !== void 0 ? _this$args$named$prev : true;
      this.pointerTypes = (_this$args$named$poin = this.args.named.pointerTypes) !== null && _this$args$named$poin !== void 0 ? _this$args$named$poin : ['touch'];
      this.didPanStart = (_this$args$named$onPa = this.args.named.onPanStart) !== null && _this$args$named$onPa !== void 0 ? _this$args$named$onPa : _fn;
      this.didPan = (_this$args$named$onPa2 = this.args.named.onPan) !== null && _this$args$named$onPa2 !== void 0 ? _this$args$named$onPa2 : _fn;
      this.didPanEnd = (_this$args$named$onPa3 = this.args.named.onPanEnd) !== null && _this$args$named$onPa3 !== void 0 ? _this$args$named$onPa3 : _fn;
      this.addEventListeners();
    }

    willRemove() {
      this.removeEventListeners();
      this.currentTouches.clear();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "didTouchStart", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTouchMove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didTouchEnd", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "didTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentPointerMove", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "documentPointerMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "documentPointerUp", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "documentPointerUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchMove", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchEnd", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype)), _class));
  _exports.default = DidPanModifier;
});