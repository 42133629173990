define("@streethub/mirage-api/resources/packaging-promotion-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('promotion-codes/:id', function (schema, request) {
      const {
        id
      } = request.params;
      const packagingPromotionCode = schema.packagingPromotionCodes.findBy(_ref => {
        let {
          code
        } = _ref;
        return code === id;
      });

      if (!packagingPromotionCode) {
        return schema.packagingPromotionCodes.none();
      }

      return packagingPromotionCode;
    });
  }
});