define("ember-svg-jar/inlined/virus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.916 1.32V.14H7.084v1.18h1.325v1.101a6.438 6.438 0 00-3.62 1.534l-.774-.775.952-.952-.836-.835-2.739 2.739.836.835.952-.952.775.775-.087.103A6.438 6.438 0 002.421 8.41H1.32V7.084H.138v3.832H1.32V9.591h1.1a6.433 6.433 0 001.535 3.62l-.775.774-.952-.952-.836.836 2.74 2.739.835-.836-.952-.951.775-.775.103.086a6.438 6.438 0 003.516 1.447v1.101H7.084v1.182h3.832V16.68H9.591v-1.1l.071-.008a6.438 6.438 0 003.548-1.526l.775.775-.952.951.836.836 2.739-2.74-.836-.835-.952.952-.775-.775.087-.103a6.438 6.438 0 001.447-3.516h1.101v1.325h1.181V7.084H16.68V8.41h-1.1a6.438 6.438 0 00-1.534-3.62l.774-.774.952.952.836-.835-2.74-2.74-.835.836.952.952-.775.775A6.438 6.438 0 009.59 2.42v-1.1h1.326zM8.824 3.688a5.376 5.376 0 00-3.576 1.56A5.382 5.382 0 003.683 9a5.507 5.507 0 00.15 1.222 5.374 5.374 0 001.415 2.53A5.387 5.387 0 009 14.317a5.41 5.41 0 001.365-.187 5.375 5.375 0 002.387-1.378A5.378 5.378 0 0014.317 9a5.384 5.384 0 00-1.565-3.752 5.375 5.375 0 00-3.928-1.56z\" fill=\"#fff\"/><path d=\"M6.421 8.653a.485.485 0 11-.969 0 .485.485 0 01.97 0zm5.002-1.73a1.385 1.385 0 11-2.77 0 1.385 1.385 0 012.77 0zM9.52 11.682a.952.952 0 11-1.905 0 .952.952 0 011.904 0z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 15.403A6.404 6.404 0 109 2.596a6.404 6.404 0 000 12.807zM5.937 7.13a1.523 1.523 0 01.002 3.046h-.002a1.523 1.523 0 110-3.046zm2.558 1.66a2.438 2.438 0 01-.144-.129l-.008-.008a2.416 2.416 0 01-.72-1.927 2.423 2.423 0 11.872 2.064zm1.544 1.552l.037.042a1.99 1.99 0 11-.038-.042z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});