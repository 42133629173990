define("@streethub/mirage-api/scenarios/_test-users", ["exports", "@streethub/mirage-api/utils/ensure-factories"], function (_exports, _ensureFactories) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    const adminUser = {
      email: 'admin@trouva.com',
      password: 'password',
      role: 'admin'
    };
    const retailUser = {
      email: 'retail@trouva.com',
      password: 'password',
      role: 'retail'
    };
    const hubUser = {
      email: 'hub@trouva.com',
      password: 'password',
      role: 'hub'
    }; // Ensure these factories are loaded, before creating the data

    (0, _ensureFactories.default)(server, ['user']);
    server.create('user', adminUser);
    server.create('user', retailUser);
    server.create('user', hubUser);
  }
});