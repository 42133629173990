define("@streethub/mirage-api/resources/zone", ["exports", "@streethub/mirage-api/utils/request"], function (_exports, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server, context) {
    // Optionally attach data to be shared across resources and requests
    // Useful for keeping state if user is logged in, across requests, etc
    context.optionalData = null;
    server.get('zones', function (schema, request) {
      const queryParams = (0, _request.normalizeQueryParams)(schema, request.queryParams);
      return schema.zones.where(queryParams);
    });
    server.get('zones/:id', function (schema, request) {
      const id = request.params.id;
      const zone = schema.zones.find(id);

      if (!zone) {
        return schema.zones.none();
      }

      return zone;
    });
  }
});