define("ember-svg-jar/inlined/packaging-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M38.867 17.656L22.383 1.172C21.758.547 20.586 0 19.727 0H3.789A3.75 3.75 0 00.04 3.75v16.016c0 .859.469 2.03 1.094 2.656l16.484 16.484c1.485 1.485 3.906 1.485 5.313 0L38.867 22.97c1.485-1.407 1.485-3.828 0-5.313zm-1.719 3.594L21.211 37.188c-.547.468-1.328.468-1.797 0L2.852 20.625c-.157-.234-.313-.625-.313-.86V3.75c0-.625.547-1.25 1.25-1.25h15.938c.312 0 .625.156.859.39l16.562 16.563c.47.469.47 1.25 0 1.797zM11.29 9.687c.86 0 1.563.704 1.563 1.563 0 .938-.704 1.563-1.563 1.563-.937 0-1.562-.626-1.562-1.563 0-.86.625-1.563 1.562-1.563zm0-2.187a3.75 3.75 0 00-3.75 3.75c0 2.11 1.64 3.75 3.75 3.75a3.75 3.75 0 003.75-3.75c0-2.031-1.719-3.75-3.75-3.75z\" fill=\"#4EA089\"/>",
    "attrs": {
      "width": "40",
      "height": "41",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});