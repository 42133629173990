define("ember-google-maps/components/g-map/directions", ["exports", "ember-google-maps/components/g-map/map-component", "ember-concurrency", "tracked-maps-and-sets", "@ember/test-waiters", "ember-google-maps/effects/tracking"], function (_exports, _mapComponent, _emberConcurrency, _trackedMapsAndSets, _testWaiters, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DirectionsAPI = DirectionsAPI;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
    directions=this.directions
    route=(component "g-map/route" getContext=@getContext directions=this.directions)
    waypoint=(component "g-map/waypoint" getContext=this.getWaypoint)
  )}}
  */
  {
    "id": "JltMs/Jw",
    "block": "{\"symbols\":[\"@getContext\",\"&default\"],\"statements\":[[18,2,[[30,[36,1],null,[[\"directions\",\"route\",\"waypoint\"],[[32,0,[\"directions\"]],[30,[36,0],[\"g-map/route\"],[[\"getContext\",\"directions\"],[[32,1],[32,0,[\"directions\"]]]]],[30,[36,0],[\"g-map/waypoint\"],[[\"getContext\"],[[32,0,[\"getWaypoint\"]]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "ember-google-maps/components/g-map/directions.hbs"
  });

  function DirectionsAPI(source) {
    return {
      get directions() {
        return source.directions;
      },

      get waypoints() {
        return source.waypoints;
      }

    };
  } // TODO should this yield something like `isRunning`? That way you can handle loading states.


  let Directions = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = class Directions extends _mapComponent.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "googleMapsApi", _descriptor, this);

      _initializerDefineProperty(this, "directions", _descriptor2, this);

      _defineProperty(this, "waypointComponents", new _trackedMapsAndSets.TrackedSet());
    }

    get name() {
      return 'directions';
    }

    get publicAPI() {
      return DirectionsAPI(this);
    }

    get waypoints() {
      var _this$options$waypoin;

      return [...((_this$options$waypoin = this.options.waypoints) !== null && _this$options$waypoin !== void 0 ? _this$options$waypoin : []), ...this.serializedWaypoints];
    }

    get serializedWaypoints() {
      return Array.from(this.waypointComponents, _ref2 => {
        let {
          location,
          stopover
        } = _ref2;
        return {
          location,
          stopover
        };
      });
    }

    setup(options) {
      let newOptions = _objectSpread(_objectSpread({}, options), {}, {
        waypoints: this.waypoints
      }); // ember-concurrency tracks its internal properties, so it ends up
      // triggering the effect a second time once it resolves. I guess we could
      // "changeset" the options to avoid this, but there's more. Because it runs
      // in the same computation as this effect, you can't even check `isRunning`
      // without triggering an error from Glimmer. That's not particularly great,
      // and I guess the solution might have to happen upstream (scheduling or
      // track/untrack frames). Let's see what comes out of Glimmer's effect
      // system and revisit.


      return (0, _tracking.untrack)(() => this.fetchDirections.perform(newOptions));
    }

    *fetchDirections() {
      var _this$events$onDirect, _this$events;

      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let directionsService = yield this.googleMapsApi.directionsService;
      let request = new Ember.RSVP.Promise((resolve, reject) => {
        directionsService.route(options, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      this.directions = yield request;
      (_this$events$onDirect = (_this$events = this.events).onDirectionsChanged) === null || _this$events$onDirect === void 0 ? void 0 : _this$events$onDirect.call(_this$events, this.publicAPI);
      return this.directions;
    } // Directions can just be restarted


    teardown() {}

    getWaypoint(waypoint) {
      this.waypointComponents.add(waypoint);
      return () => this.waypointComponents.delete(waypoint);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "directions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchDirections", [_emberConcurrency.keepLatestTask, _testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "fetchDirections"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getWaypoint", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getWaypoint"), _class.prototype)), _class));
  _exports.default = Directions;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Directions);
});